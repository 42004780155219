
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import form from 'layouts/pages/booking/schemas/form';
import { date } from 'yup/lib/locale';

dayjs.extend(utc);

export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
export  function formatDate(date) {
    const d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
  }

  export function calculateMeetingTimes(selectedDate, selectedSlot, duration) {
    const startDate = `${selectedDate} ${selectedSlot.updatedStart}`;
    const endDate = `${selectedDate} ${selectedSlot.updatedEnd}`;

    const startDateInUtc = momentTimezone.tz(startDate, selectedSlot.requiredTimezone).utc().format();

    const endDateInUtc = momentTimezone.tz(endDate, selectedSlot.requiredTimezone).utc().format();
  
    return {
      startDateTime: startDateInUtc,
      endDateTime: endDateInUtc,
    };
  }

  export function capitalizeFirstLetter( words ) {
    if(!words) return;
    
    return words.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  }

  export function splitUnderscoreWithCaps( words ) {
    if(!words) return;
    
    return words.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  }

  export function formatISODate(dateString) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options = { 
      dateStyle: 'long', 
      timeStyle: 'long', 
      timeZone: 'UTC' 
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate.slice(0, -4);
  }

  export function formatDateTimeZone(dateString, timeZone) {
    const dateTimeInUTC = momentTimezone.tz(dateString, timeZone).utc().format();
    return dateTimeInUTC;
  }

  export function dateInHumanReadableFormat (dateString) {
    const momentDate = moment(dateString);
    return momentDate.format('MMMM Do, YYYY');
  }

  export function formatTimeIn12hr (timeString) {
    return moment(timeString, "HH:mm:ss").format("hh:mm A");
    
  }

  export function formatDateTimeInDMY (timeString) {
    const formattedTime = moment.utc(timeString).format("DD-MM-YYYYTHH:mm:ss") + "Z";
    return formattedTime;
  }

  export function changeDateFormatWithTimeZone(timeString, timeZone) {
    const formattedTime = moment.utc(timeString).tz(timeZone);
    return formattedTime.format("MM-DD-YYYY HH:mm:ss");
  }

  export const formateDateString = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  
  export function convertDateTimeInHumanReadableFormat(dateTime, timeZone) {
    let dateTimeStr = changeDateFormatWithTimeZone(dateTime, timeZone);

    dateTimeStr = moment(dateTimeStr, 'DD-MM-YYYY HH:mm:ss');

const formattedDate = dateTimeStr.format('MMMM Do, YYYY');
const formattedTime = dateTimeStr.format('h:mm A');

return {
  date: formattedDate,
  time: formattedTime
};

  }

  export function calculateDateDifference(targetDateStr) {
    const targetDate = moment(targetDateStr, 'MMMM Do, YYYY');
    const currentDate = moment();

    const differenceInDays = targetDate.diff(currentDate, 'days');
    return Math.abs(differenceInDays);
  }

  export function formatDateInDMY (dateString) {

    if(!dateString) return null;
    const [year, month, day] = dateString.split("-");
  
    const formattedDate = `${day}-${month}-${year}`;
  
    return formattedDate;
  };

  export function toTitleCase(label) {
    if(label && label.length > 0) {
      return label
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    } else {
      return "";
    }
  };

  export function splitNameByComma(label) {
    return label
      .split(",")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  };

  export function constructDueDateMessage(dueDate, dueTime, message = "") {
    if (!dueDate) return null;
    const dueDateTime = dueTime ? new Date(`${dueDate.split("T")[0]}T${dueTime}`) : new Date(dueDate);
  
    if (isNaN(dueDateTime.getTime())) {
      console.error("Invalid Date:", { dueDate, dueTime });
      return {
        message: "Invalid date",
        colorCode: "#FF0000",
      };
    }
  
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    const timeDifference = dueDateTime - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    if (daysDifference > 0 && daysDifference <= 7) {
      return {
        message: "This week",
        colorCode: "#DBD9F7",
      };
    } else if (daysDifference > 7) {
      return {
        message: "Due later",
        colorCode: "#E3E3E3",
      };
    } else if (daysDifference < 0) {
      return {
        message: "Overdue",
        colorCode: "#FFD6DB",
      };
    } else {
      return {
        message: "Due today",
        colorCode: "#E3E3E3",
      };
    }
  }

  export const filteredActivityData = (data, timeZone) => {
    const activities = [];

    const addActivity = (item, heading, dataKey) => {
      const parsedProperties = JSON.parse(item.properties);

      let parsedData;
      if(item.subject_type === "user_contacts") {
        parsedData = parsedProperties?.new || {};
      } else {
        parsedData = parsedProperties[dataKey]?.new || {};
      }
      
      let updatedParsedData = null;

      if (item.subject_type === "tasks" || item.subject_type === "notes") {
        const isDuplicate = activities.some(
          (activity) =>
            activity.subject_type == item.subject_type && activity.subject_id == item.subject_id
        );
        if (isDuplicate) {
          return;
        }

        if (parsedData?.due_date) {
          let formattedDueDate = changeDateFormatWithTimeZone(parsedData.due_date, timeZone)?.split(
            " "
          )[0];
          formattedDueDate = moment(formattedDueDate, "MM-DD-YYYY").format("YYYY-MM-DD");

          const dueDateMessage = constructDueDateMessage(
            formattedDueDate,
            parsedData.due_time,
            "from deal details"
          );
          updatedParsedData = { ...parsedData, due_message: dueDateMessage };
        }
      }

      activities.push({
        id: item.id,
        subject_id: item.subject_id,
        event: item.event,
        subject_type: item.subject_type,
        data: updatedParsedData ? updatedParsedData : parsedData,
        heading,
        created_at: item.created_at,
      });
    };

    data?.forEach((item) => {
      const { id, subject_type, subject_id, event, description } = item;
      let existingActivity;

      if (subject_type !== "notes") {
        existingActivity = activities.find(
          (activity) =>
            activity.subject_type === subject_type &&
            activity.subject_id === subject_id &&
            activity.event === event
        );
      } else {
        existingActivity = activities.find(
          (activity) => activity.subject_type === subject_type && activity.subject_id == subject_id
        );
      }

      if (!existingActivity) {
        if (subject_type === "tasks") {
          const { new: task } = JSON.parse(item.properties)[1] || {};
          if (event === "Create") {
            addActivity({ ...item, key: id }, "Task created", 1);
          } else {
            addActivity(
              { ...item, key: id },
              task?.status === "done" ? "Task marked as done" : "Task re-opened",
              1
            );
          }
        } else if (subject_type === "deals") {
          if (description.includes("Task created")) {
            const { new: task } = JSON.parse(item.properties)[2] || {};
            addActivity(
              {
                id: item.id,
                key: id,
                subject_id: task.id,
                event: item.event,
                subject_type: "tasks",
                properties: item.properties,
                created_at: item.created_at,
              },
              "Task created",
              2
            );
          }

          const { old, new: deal } = JSON.parse(item.properties)[1] || {};
          if (old?.stage !== deal?.stage && event === "Update") {
            addActivity({ ...item, key: id }, "Deal activity", 1);
          }
          if (event === "Create") {
            addActivity({ ...item, key: id }, "Deal created", 1);
          }
        } else if (subject_type === "notes") {
          addActivity({ ...item, key: id }, "Note created", 1);
        }else if(subject_type ==="user_contacts") {
          let description = item.description?.includes("imported");
          addActivity({ ...item, key: id }, description == "imported" ? "Contact imported" : "Contact created", 0);
        }
      }
    });

    return activities;
  };
  export function convertDateToUTC(dateString) {
    let formattedDate = moment(dateString).format("YYYY-MM-DD");
    return dayjs.utc(formattedDate);
  }

  export function convertSelectedDateToDayjs(value) {
    return dayjs(value).utc();
  }

  export function formateDateToYYYYMMDD(dateString) {
     if(dateString) {
        return dayjs(dateString).format('YYYY-MM-DD')
     } else {
        return null;
     }
  }

  export function formatDateAndTime(dateString) {
    const date = dayjs(dateString);
    const formattedDate = date.format('YYYY-MM-DDTHH:mm');
    return formattedDate
  }
 