import { useState, useEffect, useContext, useRef } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import { FaDollarSign } from "react-icons/fa6";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FiEdit } from "react-icons/fi";
import { MdOutlineTaskAlt } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaRegThumbsDown } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { TbArrowBackUp } from "react-icons/tb";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import UpcomingActivities from "./components/UpcomingActivities";
import LatestNotes from "./components/LatestNotes";
import ContactListCard from "./components/Cards/ContactListCard";
import DealInfoCard from "./components/DealInfoCard";
import StageInfoCard from "./components/StageInfoCard";

import ConfirmDelete from "components/ConfirmDelete";
import MDSidePanel from "components/MDSidePanel";
import CreateTask from "../../../tasks/components/CreateTask";
import SidePanelContext from "context/SidePanelContext";
import NoteForm from "../../../notes/components/NoteForm";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import DealContactsCard from "./components/DealContactsCard";
import Activity from "./components/Activity";
import { constructDueDateMessage, changeDateFormatWithTimeZone } from "lib/helper";
import MoveContactToDeal from "../MoveContactToDeal";
import MDDialog from "components/MDDialog";
import { Tooltip } from "@material-ui/core";
import { toTitleCase } from "lib/helper";

const dialogWidthConfig = {
  xs: "100%",
  sm: "80%",
  md: "70%",
  lg: "40%",
  xl: "30%",
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const DealDetails = ({
  dealId,
  onClose,
  allOptions,
  activePipeline,
  deleteDeal,
  handlePageRefresh,
  setIsComponentsUpdated,
  deals,
}) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [openMenu, setOpenMenu] = useState(false);
  const [showForm, setShowForm] = useState("");
  const [activeTask, setActiveTask] = useState({});
  const [activeNote, setActiveNote] = useState({});
  const [activeDeal, setActiveDeal] = useState(null);
  const [showTaskConfirmDelete, setShowTaskConfirmDelete] = useState(false);
  const [showNoteConfirmDelete, setShowNoteConfirmDelete] = useState(false);
  const [dealActivity, setDealActivity] = useState([]);
  const [filteredText, setFilteredText] = useState("allActivity");
  const [showDialog, setShowDialog] = useState(false);
  const [activeContact, setActiveContact] = useState(null);

  const { profileDetails } = useContext(AuthContext);
  const { updateDeal, getDealDeatailById } = useContext(CrmContext);
  const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);
  const [dealContact, setDealContact] = useState([]);
  const tasksRef = useRef();

  const handleCloseDialog = () => {
    setShowDialog(false);
    setActiveContact(null);
  };

  async function fetchDealDetail() {
    const deal = await getDealDeatailById(dealId);
    if (deal) {
      setActiveDeal(deal);
    }
  }

  const filterDealActivity = (data) => {
    const activities = [];

    const addActivity = (item, heading, dataKey) => {
      const parsedProperties = JSON.parse(item.properties);
      let parsedData = parsedProperties[dataKey]?.new || {};
      let updatedParsedData = null;

      if (item.subject_type === "tasks" || item.subject_type === "notes") {
        const isDuplicate = activities.some(
          (activity) =>
            activity.subject_type == item.subject_type && activity.subject_id == item.subject_id
        );
        if (isDuplicate) {
          return;
        }

        if (parsedData?.due_date) {
          const timeZone =
            profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
          let formattedDueDate = changeDateFormatWithTimeZone(parsedData.due_date, timeZone)?.split(
            " "
          )[0];
          formattedDueDate = moment(formattedDueDate, "MM-DD-YYYY").format("YYYY-MM-DD");

          const dueDateMessage = constructDueDateMessage(
            formattedDueDate,
            parsedData.due_time,
            "from deal details"
          );
          updatedParsedData = { ...parsedData, due_message: dueDateMessage };
        }
      }

      activities.push({
        id: item.id,
        subject_id: item.subject_id,
        event: item.event,
        subject_type: item.subject_type,
        data: updatedParsedData ? updatedParsedData : parsedData,
        heading,
        created_at: item.created_at,
      });
    };

    data?.forEach((item) => {
      const { id, subject_type, subject_id, event, description } = item;
      let existingActivity;

      if (subject_type !== "notes") {
        existingActivity = activities.find(
          (activity) =>
            activity.subject_type === subject_type &&
            activity.subject_id === subject_id &&
            activity.event === event
        );
      } else {
        existingActivity = activities.find(
          (activity) => activity.subject_type === subject_type && activity.subject_id == subject_id
        );
      }

      if (!existingActivity) {
        if (subject_type === "tasks") {
          const { new: task } = JSON.parse(item.properties)[1] || {};
          if (event === "Create") {
            addActivity({ ...item, key: id }, "Task created", 1);
          } else {
            addActivity(
              { ...item, key: id },
              task?.status === "done" ? "Task marked as done" : "Task re-opened",
              1
            );
          }
        } else if (subject_type === "deals") {
          if (description.includes("Task created")) {
            const { new: task } = JSON.parse(item.properties)[2] || {};
            addActivity(
              {
                id: item.id,
                key: id,
                subject_id: task.id,
                event: item.event,
                subject_type: "tasks",
                properties: item.properties,
                created_at: item.created_at,
              },
              "Task created",
              2
            );
          }

          const { old, new: deal } = JSON.parse(item.properties)[1] || {};
          if (old?.stage !== deal?.stage && event === "Update") {
            addActivity({ ...item, key: id }, "Deal activity", 1);
          }
          if (event === "Create") {
            addActivity({ ...item, key: id }, "Deal created", 1);
          }
        } else if (subject_type === "notes") {
          addActivity({ ...item, key: id }, "Note created", 1);
        }
      }
    });

    return activities;
  };

  const getDealActivity = async (dealId) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.getDealActivity}/${dealId}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        const filteredData = filterDealActivity(responseData?.data);
        setDealActivity(filteredData);
      } else {
        setDealActivity([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDealDetail();
    getDealActivity(dealId);
  }, [dealId]);

  const handleRefreshDetailComp = () => {
    fetchDealDetail();
    setActiveNote({});
    setActiveTask({});
    setShowForm("");
    setIsComponentsUpdated(true);
    getDealActivity(dealId);
  };

  const handleRefreshOnMoveContact = () => {
    fetchDealDetail();
    setIsComponentsUpdated(true);
  };

  const handleSetTabValue = (event, newValue) => {
    setActiveTab(newValue == 0 ? "overview" : "history");
  };

  const handleCreateNote = (event) => {
    event.preventDefault();
    let contactIds =
      activeDeal?.contacts?.length > 0 ? activeDeal?.contacts?.map((contact) => contact.id) : [];
    setDealContact(contactIds);
    setShowForm("add-note");
    setActiveNote({
      deals: [{ id: activeDeal?.id }],
    });
    openPanel();
  };

  const handleCreateTask = (event) => {
    event.stopPropagation();
    setActiveTask({
      deal_ids: [activeDeal.id],
    });
    let contactIds =
      activeDeal?.contacts?.length > 0 ? activeDeal?.contacts?.map((contact) => contact.id) : [];
    setDealContact(contactIds);

    setShowForm("add-task");
    openPanel();
  };

  const handleEditTask = (task) => {
    setActiveTask({
      ...task,
      deal_ids: task?.deals?.length > 0 ? task?.deals?.map((deal) => deal.id) : (task?.deal_ids?.length>0 ? task?.deal_ids : []),
      contact_ids: task?.contacts?.length > 0 ? task?.contacts?.map((contact) => contact.id) : (task?.contact_ids?.length>0 ? task?.contact_ids : []),
    });
    setShowForm("edit-task");
    openPanel();
  };

  const handleEditNote = (note) => {
    setActiveNote(note);
    setShowForm("edit-note");
    openPanel();
  };

  const handleMarkasWon = async () => {
    const { id, name, contacts, tasks, ...rest } = activeDeal;
    const isUpdated = await updateDeal({ stage: "won" }, activeDeal?.id);
    if (isUpdated) {
      handleRefreshDetailComp();
    }
  };

  const handleMarkasLost = async () => {
    const { id, name, contacts, tasks, ...rest } = activeDeal;
    const isUpdated = await updateDeal({ stage: "lost" }, activeDeal?.id);
    if (isUpdated) {
      handleRefreshDetailComp();
    }
  };

  const handleTaskDeleteConfirmation = async (confirm) => {
    if (confirm && activeTask?.id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.deletedTask}/${activeTask?.id}`, {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          tasksRef.current?.getAllTasks();
          getDealActivity(dealId);
          setIsComponentsUpdated(true);
          toast.success(responseData?.message || "Task Deleted Successfully");
        } else {
          throw new Error(responseData?.error || "Some error occurred while deleting task");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    setActiveTask({});
    setShowTaskConfirmDelete(false);
  };
  const handleNoteDeleteConfirmation = async (confirm) => {
    if (confirm && activeNote?.id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.deleteNote}/${activeNote?.id}`, {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          handleRefreshDetailComp();
          toast.success(responseData?.message || "Note Deleted Successfully");
        } else {
          throw new Error(responseData?.error || "Some error occurred while deleting note");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    setActiveNote({});
    setShowNoteConfirmDelete(false);
  };

  const deleteTask = async (task) => {
    setActiveTask(task);
    setShowTaskConfirmDelete(true);
    setShowForm("delete-task");
  };

  const deleteNote = async (note) => {
    setActiveNote(note);
    setShowNoteConfirmDelete(true);
    setShowForm("delete-note");
  };

  const stageAvaliableInPipeline = (stage) => {
    if (!activePipeline) {
      return false;
    }

    if (activePipeline?.stages?.length > 0) {
      return activePipeline?.stages?.some((pipelineStage) => pipelineStage.key === stage);
    }

    return false;
  };

  return (
    <>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDBox className="icon-shade" style={{ marginRight: "20px" }} onClick={onClose}>
            <TbArrowBackUp color="#3A93EE" />
          </MDBox>
          <MDBox
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: "#DEF4F8",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaDollarSign size={24} color="#276762" />
          </MDBox>
          <MDBox ml={1} display="flex" alignItems="center">
            {activeDeal?.name?.length > 30 ? (
              <Tooltip title={activeDeal?.name} placement="bottom">
                <MDTypography variant="h5" fontWeight="medium">
                  {activeDeal?.name && toTitleCase(activeDeal?.name)}
                </MDTypography>
              </Tooltip>
            ) : (
              <MDTypography varia nt="h5" fontWeight="medium">
                {activeDeal?.name && toTitleCase(activeDeal?.name)}
              </MDTypography>
            )}
          </MDBox>
          <MDBox ml={2} display="flex" alignItems="center">
            <MDTypography variant="subtitle2" fontWeight="regular" color="text">
              Added by you on {moment(activeDeal?.created_at).format("MMM D, YYYY")}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <MDBox display="flex" alignItems="end" justifyContent="space-between">
            <MDBox sx={{ width: "200px", display: "flex" }}>
              <AppBar
                position="static"
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  "& .MuiTabs-root": { backgroundColor: "transparent" },
                }}
              >
                <Tabs
                  orientation="horizontal"
                  value={activeTab === "overview" ? 0 : 1}
                  onChange={handleSetTabValue}
                  sx={{
                    "& .MuiTab-root": { textAlign: "center" },
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                >
                  <Tab
                    label="Overview"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "transparent",
                    }}
                  />
                  <Tab
                    label="History"
                    sx={{
                      backgroundColor: "transparent",
                    }}
                  />
                </Tabs>
              </AppBar>
            </MDBox>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDBox
                ml={1}
                my={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                onClick={handleCreateNote}
              >
                <MDBox className="icon-shade">
                  <FiEdit />
                </MDBox>
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Note
                </MDTypography>
              </MDBox>
              <MDBox
                ml={1}
                my={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                onClick={handleCreateTask}
              >
                <MDBox className="icon-shade">
                  <MdOutlineTaskAlt />
                </MDBox>
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Task
                </MDTypography>
              </MDBox>
              <MDMenu
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
                menuWidth={230}
                triggerElement={
                  <MDBox
                    ml={1}
                    my={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <MDBox className="icon-shade">
                      <HiDotsHorizontal />
                    </MDBox>
                    <MDTypography variant="caption" fontWeight="regular" color="text">
                      More
                    </MDTypography>
                  </MDBox>
                }
              >
                {stageAvaliableInPipeline("won") && (
                  <MenuItem disableRipple onClick={handleMarkasWon}>
                    <MDBox mr={1} mt={0.5}>
                      <FaRegThumbsUp size={14} />
                    </MDBox>
                    Mark as won
                  </MenuItem>
                )}

                {stageAvaliableInPipeline("lost") && (
                  <MenuItem disableRipple onClick={handleMarkasLost}>
                    <MDBox mr={1} mt={0.5}>
                      <FaRegThumbsDown size={14} />
                    </MDBox>
                    Mark as lost
                  </MenuItem>
                )}
                {(stageAvaliableInPipeline("won") || stageAvaliableInPipeline("lost")) && (
                  <Divider sx={{ my: 0.5 }} />
                )}
                <MenuItem disableRipple onClick={() => deleteDeal(activeDeal)}>
                  <MDBox mr={1} mt={0.5}>
                    <MdDeleteOutline size={17} color="red" />
                  </MDBox>
                  Delete
                </MenuItem>
              </MDMenu>
              {/* </MDBox> */}
            </MDBox>
          </MDBox>
          <MDBox sx={{ border: "1px solid #cdcdcd", mt: -0.5, ml: 0.5 }}></MDBox>

          {activeTab === "overview" ? (
            <Grid container mt={0} spacing={2}>
              <Grid item xs={3}>
                <Card>
                  <DealInfoCard
                    activeDeal={activeDeal}
                    handleRefreshDetailComp={handleRefreshDetailComp}
                  />
                </Card>
              </Grid>

              <Grid item xs={6}>
                <StageInfoCard
                  activeDeal={activeDeal}
                  handleRefreshDetailComp={handleRefreshDetailComp}
                />

                <MDBox mt={2}>
                  <UpcomingActivities
                    ref={tasksRef}
                    activeDeal={activeDeal}
                    allOptions={allOptions}
                    handleEditTask={handleEditTask}
                    deleteTask={deleteTask}
                    setShowForm={setShowForm}
                    openPanel={openPanel}
                    getDealActivity={getDealActivity}
                    setIsComponentsUpdated={setIsComponentsUpdated}
                    setDealContact={setDealContact}
                  />
                </MDBox>

                <MDBox mt={2}>
                  <LatestNotes
                    activeDeal={activeDeal}
                    setFilteredText={setFilteredText}
                    setActiveTab={setActiveTab}
                  />
                </MDBox>
                <MDBox mt={2}>
                  <Activity
                    heading={"Recent history"}
                    activeDeal={activeDeal}
                    allOptions={allOptions}
                    getActivity={getDealActivity}
                    activityLogs={dealActivity}
                    handleEditTask={handleEditTask}
                    deleteTask={deleteTask}
                    openPanel={openPanel}
                    setShowForm={setShowForm}
                    deleteNote={deleteNote}
                    handleEditNote={handleEditNote}
                    getAllTasks={() => {
                      tasksRef.current?.getAllTasks();
                      getDealActivity(dealId);
                    }}
                    setIsComponentsUpdated={setIsComponentsUpdated}
                    filteredText={"allActivity"}
                    setFilteredText={setFilteredText}
                    setActiveTab={setActiveTab}
                    showActivityForComp="deals"
                  />
                </MDBox>
              </Grid>

              <Grid item xs={3}>
                <ContactListCard
                  contacts={activeDeal?.contacts}
                  setShowForm={setShowForm}
                  openPanel={openPanel}
                  activeDeal={activeDeal}
                  setActiveContact={setActiveContact}
                  handleRefreshDetailComp={handleRefreshDetailComp}
                  setShowDialog={setShowDialog}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container mt={0} spacing={2}>
              <Grid item xs={12}>
                <Activity
                  type={"History"}
                  activeDeal={activeDeal}
                  allOptions={allOptions}
                  getActivity={getDealActivity}
                  activityLogs={dealActivity}
                  handleEditTask={handleEditTask}
                  deleteTask={deleteTask}
                  openPanel={openPanel}
                  setShowForm={setShowForm}
                  deleteNote={deleteNote}
                  handleEditNote={handleEditNote}
                  getAllTasks={() => {
                    tasksRef.current?.getAllTasks();
                    getDealActivity(dealId);
                  }}
                  filteredText={filteredText}
                  setFilteredText={setFilteredText}
                  setIsComponentsUpdated={setIsComponentsUpdated}
                  setActiveTab={setActiveTab}
                  showActivityForComp="deals"
                />
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>

      {showForm === "add-task" && (
        <MDSidePanel
          header="Add Task"
          onClose={() => {
            setShowForm("");
            setDealContact([]);
          }}
        >
          <CreateTask
            onClose={() => {
              setShowForm("");
              setDealContact([]);
            }}
            handleRefresh={handleRefreshDetailComp}
            taskDetail={null}
            showFormInCard={false}
            dealIds={[activeDeal.id]}
            dealContact={dealContact}
          />
        </MDSidePanel>
      )}
      {showForm === "edit-task" && (
        <MDSidePanel header="Edit Task" onClose={() => setShowForm("")}>
          <CreateTask
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={() => {
              handleRefreshDetailComp();
              getDealActivity(dealId);
              setIsComponentsUpdated(true);
            }}
            taskDetail={activeTask}
            showFormInCard={false}
            dealIds={activeTask?.deal_ids}
            type="edit from deal details"
          />
        </MDSidePanel>
      )}
      {showForm === "add-note" && (
        <MDSidePanel header="Create Note" onClose={() => setShowForm("")}>
          <NoteForm
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={handleRefreshDetailComp}
            noteDetail={activeNote}
            showFormInCard={false}
            dealContact={
              activeDeal?.contacts?.length > 0
                ? activeDeal?.contacts?.map((contact) => contact.id)
                : []
            }
            showNumberofRows={5}
          />
        </MDSidePanel>
      )}
      {showForm === "edit-note" && (
        <MDSidePanel header="Update Note" onClose={() => setShowForm("")}>
          <NoteForm
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={handleRefreshDetailComp}
            noteDetail={activeNote}
            showFormInCard={false}
            showNumberofRows={5}
          />
        </MDSidePanel>
      )}
      {showForm === "associate-deal-contacts" && (
        <MDSidePanel header="Add contacts to your deal" onClose={() => setShowForm("")}>
          <DealContactsCard
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={handleRefreshDetailComp}
            dealDetail={activeDeal}
            showFormInCard={false}
            dealId={activeDeal?.id}
          />
        </MDSidePanel>
      )}
      {showForm === "delete-task" && (
        <ConfirmDelete
          title="Delete Task?"
          message="Are you sure you want to delete this task?"
          confirm={handleTaskDeleteConfirmation}
          showDialog={showTaskConfirmDelete}
        />
      )}
      {showForm === "delete-note" && (
        <ConfirmDelete
          title="Delete Note?"
          message="Are you sure you want to delete this note?"
          confirm={handleNoteDeleteConfirmation}
          showDialog={showNoteConfirmDelete}
        />
      )}

      {showDialog && (
        <MDDialog
          open={showDialog}
          onClose={handleCloseDialog}
          title={`Move ${activeContact.name} to other deal(s)`}
          widthConfig={dialogWidthConfig}
        >
          <MoveContactToDeal
            activeContact={activeContact}
            handleClose={handleCloseDialog}
            deals={deals}
            activeContactDeal={activeDeal}
            handleRefresh={handleRefreshOnMoveContact}
          />
        </MDDialog>
      )}
    </>
  );
};

export default DealDetails;
