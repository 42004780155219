// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import MDButton from "components/MDButton";


function DefaultInfoCard({ title, description, info, social, action, shadow, handleSubscriptionPreference }) {
  const labels = [];
  const values = [];
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    let modifiedLabel = el.charAt(0).toUpperCase() + el.slice(1);
    modifiedLabel = modifiedLabel.replace(/([A-Z])/g, " $1").trim();
    modifiedLabel = modifiedLabel.replace(/\s([a-z])/g, (match) => match.toUpperCase());
    labels.push(modifiedLabel);
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" alignItems="start" py={1} pr={2}>
      {/* Label */}
      <MDBox
        display="flex"
        alignItems="start"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {label}:&nbsp;
        </MDTypography>
      </MDBox>

      {/* Values */}
      <MDBox
        display="flex"
        alignItems="start"
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{values[key] || "N/A"}
        </MDTypography>
      </MDBox>

    </MDBox>
  ));



  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {description && (
          <MDBox mb={2} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {description}
            </MDTypography>
          </MDBox>
        )}
        <MDBox>{renderItems}</MDBox>
        {info?.subscriptionPreference ? (
         <MDButton 
            variant="gradient"
            color="dark"
            size="small"
            onClick={(e) => handleSubscriptionPreference()}
            sx={{
              marginTop: '1rem'
            }}
          >
            {info["subscriptionPreference"] === 'Cancel' ? 'Resume' : 'Cancel'} the user subscription
          </MDButton>
        ) : <></>}
        
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
  shadow: true,
  handleSubscriptionPreference: () => {},
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  shadow: PropTypes.bool,
  handleSubscriptionPreference: PropTypes.func,
};

export default DefaultInfoCard;
