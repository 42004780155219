import { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbArrowBackUp } from "react-icons/tb";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import MDButton from "components/MDButton";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { constructDueDateMessage, toTitleCase } from "lib/helper";
import { Tooltip } from "@material-ui/core";
import { DATE_FORMAT } from "constants/global"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const TaskList = ({
  handleEditTask,
  deleteTask,
  task,
  handleExpandClick,
  taskId,
  dueDateMessage,
  getTypeValue,
  addBorder,
  getAllTasks,
  openPanel,
  setShowForm,
  handleUpdateTaskState,
  setIsComponentsUpdated,
  setDealContact
}) => {
  const { profileDetails } = useContext(AuthContext);
  const [buttonText, setButtonText] = useState("Mark as Done");
  const { updateTask } = useContext(CrmContext);

  useEffect(() => {
    if (task.status === "pending") {
      setButtonText("Mark as Done");
    } else {
      setButtonText("Re-open task");
    }
  }, [task]);

  const handleUpdateTask = async (data, id) => {
    const isUpdated = await updateTask(data, id);
    if (isUpdated) {
      setIsComponentsUpdated(true);
      if (data.status == "pending") {
        setButtonText("Mark as Done");
        handleUpdateTaskState(id, "pending");
      } else {
        setButtonText("Re-open task");
        handleUpdateTaskState(id, "done");
      }
    } else {
      setButtonText("Mark as Done");
    }
  };

  const updateTaskStatus = (task) => {
    if (task.status === "pending") {
      handleUpdateTask({ status: "done" }, task.id);
    } else {
      handleUpdateTask({ status: "pending" }, task.id);
    }
  };

  const handleCreateNewNote = () => {
    setShowForm("add-note");
    openPanel();
  };
  return (
    <>
      <MDBox key={task?.id}>
        <MDBox display="flex" justifyContent="space-between" py={3}>
          <MDBox>
            {task?.name?.length > 20 ?(
              <Tooltip title={task?.name} placement="bottom">
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" className="hide-extra-text">
              {task?.name?.slice(0, 20) + "..." }
            </MDTypography>
            </Tooltip>
            ):(
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" className="hide-extra-text">
              {task?.name}
            </MDTypography>
            )}
            <MDBox display="flex" alignItems="center">
              {task?.due_date && (
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  {moment(task?.due_date).format(DATE_FORMAT)}
                </MDTypography>
              )}
              {task?.due_time && (
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  &nbsp;at&nbsp;{moment(task?.due_time, "HH:mm:ss").format("HH:mm")}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <Chip
              label={getTypeValue(task?.type)}
              size="medium"
              style={{ backgroundColor: "#DBF4F4" }}
            />
            {task?.due_date && (
              <Chip
                label={buttonText === "Mark as Done" ? dueDateMessage.message : "Done"}
                size="medium"
                style={{
                  backgroundColor: buttonText === "Mark as Done" ? dueDateMessage.colorCode : "#D8EFE8",
                  marginLeft: "10px",
                }}
              />
            )}

            <MDButton
              color="dark"
              variant="outlined"
              size="small"
              style={{ marginLeft: "10px" }}
              onClick={() => updateTaskStatus(task)}
            >
              {buttonText}
            </MDButton>

            <MDBox>
              <ExpandMore
                expand={taskId === task.id}
                onClick={() => handleExpandClick(task.id)}
                aria-expanded={taskId === task.id}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </MDBox>
          </MDBox>
        </MDBox>
        <Collapse in={taskId === task.id} timeout="auto" unmountOnExit>
          <MDBox display="flex" alignItems="start">
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="h6" fontWeight="regular" color="text">
                Due date
              </MDTypography>
              {task?.due_date && task?.due_time && (
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="h6" fontWeight="regular">
                    {moment(task?.due_date).format(DATE_FORMAT)}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="regular">
                    &nbsp;at&nbsp;{moment(task?.due_time, "HH:mm:ss").format("HH:mm")}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>

            <MDBox display="flex" flexDirection="column" ml={3}>
              <MDTypography variant="h6" fontWeight="regular" color="text">
                User Assigned
              </MDTypography>
              <MDTypography variant="h6" fontWeight="regular">
                {profileDetails?.full_name}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-start" my={2}>
            {buttonText === "Mark as Done" ? (
              <>
                <MDBox className="btn-shade" onClick={() => handleEditTask(task)}>
                  <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                    Edit
                  </MDTypography>
                </MDBox>
                <MDBox className="btn-shade" ml={2} onClick={() => deleteTask(task)}>
                  <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                    Delete
                  </MDTypography>
                </MDBox>
              </>
            ) : (
              <>
                <MDBox className="btn-shade" onClick={() => handleCreateNewNote()}>
                  <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                    Add a note
                  </MDTypography>
                </MDBox>
                <MDBox className="btn-shade" ml={2} onClick={() => getAllTasks()}>
                  <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                    Dismiss
                  </MDTypography>
                </MDBox>
              </>
            )}
          </MDBox>
        </Collapse>
        {addBorder && <MDBox mt={0} sx={{ border: "1px solid #c7c7c7" }}></MDBox>}
      </MDBox>
    </>
  );
};

const UpcomingActivities = forwardRef((props, ref) => {
  const {
    activeDeal,
    allOptions,
    handleEditTask,
    deleteTask,
    openPanel,
    setShowForm,
    getDealActivity,
    setIsComponentsUpdated,
    setDealContact
  } = props;
  const [expanded, setExpanded] = useState(true);
  const [taskId, setTaskId] = useState(null);
  const { updateTask, getTasksByDealId } = useContext(CrmContext);
  const [taskToUpdate, setTaskToUpdate] = useState({});
  const [tasks, setTasks] = useState([]);
  const handleExpandClick = (id) => {
    if (taskId === id) {
      setTaskId(null);
    } else {
      setTaskId(id);
    }
  };

  const handleUpdateTaskState = (id, status) => {
    getDealActivity(activeDeal?.id);
    const index = tasks.findIndex((task) => task.id === id);
    if (index !== -1) {
      const updatedTasks = [...tasks];
      updatedTasks[index] = { ...updatedTasks[index], status: status };
      setTasks(updatedTasks);
    }
  };

  const getAllTasks = async () => {
    const tasks = await getTasksByDealId(activeDeal?.id);
    if (tasks) {
      setTasks(tasks);
    } else {
      setTasks([]);
    }
  };

  useImperativeHandle(ref, () => ({
    getAllTasks,
  }));

  const getTypeValue = (key) => {
    const type = allOptions?.types?.find((type) => type.key === key);
    if (type) {
      return type.value;
    } else {
      return "";
    }
  };

  useEffect(() => {
    setTasks(activeDeal?.tasks);
  }, [activeDeal]);

  return (
    <Card>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Upcoming activities
        </MDTypography>
        {tasks?.length > 0 ? (
          <MDBox>
            {tasks?.map((task, index) => {
              let dueDateMessage = task?.due_date ? constructDueDateMessage(task?.due_date, task?.due_time, "from upcoming activities") : "";
              return (
                <TaskList
                  handleEditTask={handleEditTask}
                  deleteTask={deleteTask}
                  task={task}
                  addBorder={index !== tasks?.length - 1}
                  dueDateMessage={dueDateMessage}
                  handleExpandClick={handleExpandClick}
                  taskId={taskId}
                  getTypeValue={getTypeValue}
                  getAllTasks={getAllTasks}
                  setShowForm={setShowForm}
                  openPanel={openPanel}
                  handleUpdateTaskState={handleUpdateTaskState}
                  setIsComponentsUpdated={setIsComponentsUpdated}
                  setDealContact={setDealContact}
                />
              );
            })}
          </MDBox>
        ) : (
          <MDBox display="flex" justifyContent="center" py={2}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              textTransform="capitalize"
              color="text"
            >
              No Upcoming activities
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
});

export default UpcomingActivities;
