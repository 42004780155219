import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import API_ENDPOINTS from "apiConfig";
import ConfirmDelete from "components/ConfirmDelete";


function Field({ fieldId, label, name, type, isCustom, isCreate, handleDeleteCard, options, handleRefresh, index }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false); //(chatId==null)?useState(true):useState(false);
  const [newLabel, setNewLabel] = useState(label);
  const [newName, setNewName] = useState(name);
  const [newType, setNewType] = useState(type)
  const [showOptionsField, setShowOptionsField] = useState(false);
  const [fields, setFields] = useState([{ id: 1, label: "", name: "", type: "text" }]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  function isSnakeCase(str) {
    const snakeCaseRegex = /^[a-z]+(_[a-z]+)*$/;
    return snakeCaseRegex.test(str);
  }

  function isEachWordStartsWithUppercase(str) {
    const wordRegex = /^[A-Z][a-z]*$/;
    const words = str.split(' ');
  
    return words.every((word) => wordRegex.test(word));
  }

  const handleSave = async (event) => {
    event.preventDefault();

    if(newLabel.trim() === '') {
      toast.error("Label is required");
      return;
    }

    else if(newName.trim() === '') {
      toast.error("Name is required");
      return;
    }

    else if(!isSnakeCase(newName)) {
      toast.error("Name must be in snake case, only letters and underscores are allowed EX: first_name");
      return;
    } 

    else if(!isEachWordStartsWithUppercase(newLabel)) {
      toast.error("Each word in label must start with an uppercase letter, only letters and spaces are allowed EX: First Name");
      return;
    }

    const options = [];
    const optionsList = fields.filter((field) => field.label.trim() !== "") // Only save fields with a label
    optionsList.map((item) => {
      options.push(item?.label);
    })
    const data = {
      label: newLabel,
      name: newName,
      type: newType,
      options: JSON.stringify(options) // Only save fields with a label
    };

    if (isEditing) {
      const response = await fetch(`${API_ENDPOINTS?.updateField}/${fieldId}`, {
        method: "PUT",
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(data)
      })

      let jsonResponse = await response.json();
      if (response.ok) {
        toast.success(jsonResponse.message);
      } else {
        toast.error(jsonResponse.error);
      }
      setIsEditing(false);
      setShowOptionsField(false);
      handleRefresh();
    } else {
      const response = await fetch(`${API_ENDPOINTS?.createField}`, {
        method: "POST",
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(data)
      })
      let jsonResponse = await response.json();
      if (response.ok) {
        handleDeleteCard();
        toast.success(jsonResponse.message);
      } else {
         toast.error(jsonResponse.error);
      }
    }
  };

  const resizeCard = () => {
    const card = document.body.querySelector('#field-list');

    if (showOptionsField) {
      card.style.width = '70%'
    } else {
      card.style.width = '50%'
    }
  }

  const handleDelete = async () => {
    // setShowOptionsField(false);
    if (fieldId) {
      const response = await fetch(`${API_ENDPOINTS?.deleteField}/${fieldId}`, {
        method: "DELETE",
        headers: {
          "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      if (response.ok) {
        handleRefresh()
        toast.success("Field Deleted Successfully");

      }
    }
    handleDeleteCard();
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const existingOptions = options ? JSON.parse(options) : '';

    setIsEditing(true);
    if (existingOptions) {
      setFields(
        existingOptions.map((item) => item ? { id: item, label: item } : { id: Date.now(), label: "", type: "text" })
      )
      setShowOptionsField(true);
    }
  };

  const handleLabelChange = (event) => {
    event.preventDefault();
    setNewLabel(event.target.value);
  }

  const handleNameChange = (event) => {
    event.preventDefault();
    setNewName(event.target.value);
  }

  const handleTypeChange = (event) => {
    event.preventDefault();
    setNewType(event.target.value);
    if (event.target.value == 'select' || event.target.value == 'multiselect' || event.target.value == 'radio') {
      setShowOptionsField(true);
      if(fields && fields.length < 1) {
        setFields([{ id: 1, label: "", type: "text" }]);
      }
    } else {
      setShowOptionsField(false);
    }
  }

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      await handleDelete();
    }
    setShowConfirmDelete(false);
  };

  const deleteFieldById = async () => {
    setShowConfirmDelete(true);
  };

  return (
    <>
      <MDBox component="form" onSubmit={handleSave}>
        <MDBox
          component="li"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgColor={darkMode ? "transparent" : "grey-100"}
          borderRadius="lg"
          p={1}
          sx={!isCreate && index % 2 === 0 && { backgroundColor: '#f0f0f0'}}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              {(isEditing || isCreate) ? (
                <MDBox sx={{display:"inline-block", width:"25%", minWidth:"25%", mb:0.8, pr:0.4}}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  placeholder="Label"
                  label="Label"
                  value={newLabel}
                  onChange={(e) => handleLabelChange(e)}
                  required
                  sx={{display:"inline-block", width:"100%", minWidth:"100%"}}
                />
                </MDBox>

              ) : (
                <MDTypography variant="button" sx={{ fontWeight: "400" }} width="39%">
                  {newLabel}
                </MDTypography>
              )}

              {(isEditing || isCreate) ? (
                <MDBox sx={{display:"inline-block", width:"25%", minWidth:"25%", mb:0.8, pr:0.4}}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  placeholder="Label"
                  label="Name"
                  value={newName}
                  onChange={(e) => handleNameChange(e)}
                  required
                  sx={{display:"inline-block", width:"100%", minWidth:"100%"}}
                />
                </MDBox>

              ) : (
                <MDTypography variant="button" sx={{ fontWeight: "400" }} width="39%">
                  {newName}
                </MDTypography>
              )}

              {/* &nbsp;&nbsp;&nbsp; */}
              {(isEditing || isCreate) ? (
                <FormControl variant="standard" sx={{ width: "25%", minWidth: "25%", pr:0.4 }} >
                  <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={newType}
                    onChange={handleTypeChange}
                    label="Type"
                    required
                    sx={{width:'100%', minWidth:'100%'}}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'text'}>Text</MenuItem>
                    <MenuItem value={'textarea'}>Textarea</MenuItem>
                    <MenuItem value={'number'}>Number</MenuItem>
                    <MenuItem value={'date'}>Date</MenuItem>
                    <MenuItem value={'select'}>Select</MenuItem>
                    <MenuItem value={'multiselect'}>Multiselect</MenuItem>
                    <MenuItem value={'checkbox'}>Checkbox</MenuItem>
                    <MenuItem value={'radio'}>Radio</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <MDTypography variant="button" sx={{ pl:0.4, fontWeight: "400" }} width={"40%"}>
                  {newType.charAt(0).toUpperCase() + newType.slice(1).toLowerCase()}
                </MDTypography>
              )}

              <MDBox
                display="flex"
                alignItems="center"
                mt={{ xs: 2, sm: 0 }}
                ml={{ xs: -1.5, sm: 0 }}
                width={isCreate || isEditing ? '26%' : '40%'}
                justifyContent='center'
              >
                <MDBox>
                  <MDButton
                    variant="text"
                    color="error"
                    onClick={deleteFieldById}
                    disabled={!isCustom && !isCreate ? true : false}
                    sx={{minWidth:"0", p:0.5}}
                  >
                    <Icon>delete</Icon>&nbsp;
                  </MDButton>
                </MDBox>
                {(isEditing || isCreate) ? (
                  <MDButton variant="text" color={darkMode ? "white" : "dark"} type="submit" sx={{minWidth:"0", p:0.5}}>
                    <Icon>save</Icon>&nbsp;
                  </MDButton>
                ) : (
                  <MDButton
                    variant="text"
                    color={darkMode ? "white" : "dark"}
                    type={"button"}
                    onClick={handleEdit}
                    disabled={!isCustom ? true : false}
                    sx={{minWidth:"0", p:0.5}}
                  >
                    <Icon>edit</Icon>&nbsp;
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <ConfirmDelete
          title="Delete User?"
          message="Are you sure you want to delete this field?"
          confirm={handleDeleteConfirmation}
          showDialog={showConfirmDelete}
        />
    </>
  );
}

Field.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  isCustom: PropTypes.bool,
  isCreate: PropTypes.bool,
  handleDeleteCard: PropTypes.func,
  options: PropTypes.string,
  fieldId: PropTypes.number,
  handleRefresh: PropTypes.func

};

export default Field;
