import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbArrowBackUp } from "react-icons/tb";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import MDButton from "components/MDButton";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { formatDistanceToNow } from "date-fns";
import { HiDotsVertical } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import { BsDashCircle } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineMoveUp } from "react-icons/md";
const ContactListCard = ({ contacts, setShowForm, openPanel, activeDeal, handleRefreshDetailComp, setActiveContact, setShowDialog }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { deleteDealContact } = useContext(CrmContext);

  const handleMoveContact = (contact) => {
    setActiveContact(contact);
    setShowDialog(true);
  };

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" justifyContent="space-between">

        <MDTypography variant="h6" fontWeight="medium">
          {`Contacts (${contacts?.length || 0})`}
        </MDTypography>
        <MDBox className="btn-shade" onClick={() => { openPanel(); setShowForm('associate-deal-contacts')}}>
            <FiEdit2 />
        </MDBox>
        </MDBox>

        <MDBox>
          {contacts?.map((contact) => {
            let email = contact?.work_email;
            return (
              <MDBox key={contact?.id}>
              <MDBox display="flex" justifyContent="space-between" mt={1}>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    {contact?.name}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDMenu
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                    menuWidth={230}
                    triggerElement={
                      <MDBox className="btn-shade">
                        <HiDotsVertical />
                      </MDBox>
                    }
                  >
                    {/* <MenuItem disableRipple>
                      <MDBox mr={1} mt={0.5}>
                        <FaUser size={17} />
                      </MDBox>
                      View this contact
                    </MenuItem> */}
                    <MenuItem disableRipple onClick={() => deleteDealContact(activeDeal?.id, contact?.id, handleRefreshDetailComp)}>
                      <MDBox mr={1} mt={0.5}>
                        <BsDashCircle size={17} />
                      </MDBox>
                      Remove association
                    </MenuItem>
                    <MenuItem disableRipple onClick={() => handleMoveContact(contact)}>
                      <MDBox mr={1} mt={0.5}>
                        <MdOutlineMoveUp size={17} />
                      </MDBox>
                      Move contact
                    </MenuItem>
                  </MDMenu>
                </MDBox>
              </MDBox>
              <MDBox>
                {email && (
                    <MDBox mt={-2}>
                    <MDTypography variant="caption" fontWeight="regular" color="text">
                      {email}
                    </MDTypography>
                    </MDBox>
                )}

                {contact?.phone_number && (
                    <MDTypography variant="caption" fontWeight="regular" color="text">
                      {contact?.phone_number}
                    </MDTypography>
                )}
              </MDBox>
              </MDBox>
            );
          })}
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ContactListCard;
