import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import AuthContext from "context/Authcontext";
import Grid from "@mui/material/Grid";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

function getSimilarityPercentage(fileField, contactField) {
  try {
    const computeLevenshteinDistance = (fileValue, contactValue) => {
      try {
        const fileLength = fileValue.length;
        const contactLength = contactValue.length;
        const similarityMatrix = Array.from({ length: fileLength + 1 }, () => Array(contactLength + 1).fill(0));
    
        // Initialize the similarity matrix
        for (let fileIndex = 0; fileIndex <= fileLength; fileIndex++) similarityMatrix[fileIndex][0] = fileIndex;
        for (let contactIndex = 0; contactIndex <= contactLength; contactIndex++) similarityMatrix[0][contactIndex] = contactIndex;
    
        // Fill the similarity matrix
        for (let fileIndex = 1; fileIndex <= fileLength; fileIndex++) {
          for (let contactIndex = 1; contactIndex <= contactLength; contactIndex++) {
            if (fileValue[fileIndex - 1].toLowerCase() === contactValue[contactIndex - 1].toLowerCase()) {
              similarityMatrix[fileIndex][contactIndex] = similarityMatrix[fileIndex - 1][contactIndex - 1];
            } else {
              similarityMatrix[fileIndex][contactIndex] = Math.min(
                similarityMatrix[fileIndex - 1][contactIndex],       // Deletion
                similarityMatrix[fileIndex][contactIndex - 1],       // Insertion
                similarityMatrix[fileIndex - 1][contactIndex - 1]    // Substitution
              ) + 1;
            }
          }
        }
    
        return similarityMatrix[fileLength][contactLength];
      }  catch (error) {
         console.log(error);
      }
    };
  
    const editDistance = computeLevenshteinDistance(fileField, contactField);
    const maxFieldLength = Math.max(fileField.length, contactField.length);
    return ((maxFieldLength - editDistance) / maxFieldLength) * 100;
  } catch(error) {
    console.log(error);
  }
}

const MappingFields = ({
  fileName,
  usersData,
  fields,
  setFields,
  mappedFields,
  setMappedFields,
  validateComponent,
  setValidateComponent,
  handleNext,
  fileFieldNames
}) => {
  const [SampleData, setSampleData] = useState([]);
  function findSimilarField(fieldFromFile, contactFields, threshold = 80) {
    for (const field of contactFields) {
      const similarity = getSimilarityPercentage(fieldFromFile, field);
      if (similarity >= threshold) {
        return field;
      }
      console.log("similarity", similarity);
    }
    return null;
  }

  const checkForRequiredFields = (fieldName) => {
      let requiredFields = fields.filter((field) => field.is_required);
      if(requiredFields.find((field) => field.label === fieldName)) {
        return true
      } else {
        return false
      }
  }

  const handleMapFields = (fields) => {
    try {

      const fileFieldNames = Object.entries(usersData[0]).map(([key, value]) => {
        return key
      });
      console.log("fileFieldNames", fileFieldNames);
      const updatedFields = {};
      const requiredFields = fields.filter((field) => field.is_required);
      const notRequiredFields = fields.filter((field) => !field.is_required);
      requiredFields.forEach((field) => {
        updatedFields[field.label] = "";
      })
      notRequiredFields.forEach((field) => {
        updatedFields[field.label] = "";
      })
      console.log("requiredFields", requiredFields);

      Object.entries(updatedFields).forEach(([key, value]) => {
        const similarField = findSimilarField(key, fileFieldNames);
  
        if (!similarField) {
          updatedFields[key] = "Do not Import";
        } else {
          if (Object.values(updatedFields).includes(similarField)) {
            updatedFields[key] = "Do not Import";
          } else {
            updatedFields[key] = similarField;
          }
        }
      });
      console.log("updatedFields", updatedFields);
      setMappedFields(updatedFields);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newValue, key) => {
    const valuesInMappedFields = Object.values(mappedFields);
    if (newValue !== "Do not Import" && valuesInMappedFields.includes(newValue)) {
      const mappedKey = Object.keys(mappedFields).find((k) => mappedFields[k] === newValue);
      toast.error(
        `This field is already mapped with ${mappedKey}. Please choose a different field.`
      );
    }
    console.log("newValue", newValue);
    setMappedFields({ ...mappedFields, [key]: newValue });
  };
  const handleMoveToNext = () => {
    // Filter out empty strings from mapped fields
    const valuesInMappedFields = Object.values(mappedFields).filter((value) => value?.trim() !== "" && value !== "Do not Import" && value !== null);
    const uniqueValues = new Set(valuesInMappedFields);

    // Check for duplicate mappings
    if (uniqueValues.size !== valuesInMappedFields.length) {
      toast.error("Multiple fields map to the same field. Please choose unique fields.");
      return;
    }
    // Get all required fields
    const requiredFields = fields.filter((field) => field.is_required);
    // Check if all required fields are mapped
    for (const field of requiredFields) {
      if( mappedFields[field.label] === "Do not Import" || !mappedFields[field.label] || mappedFields[field.label] === null) {
        toast.error(`Field ${field.label} is required. Please map it.`);
        return;
      }
    }

    setValidateComponent((prev) => ({
      ...prev,
      mapFields: true,
    }));
    handleNext();
  };

  useEffect(() => {
    if (validateComponent?.mapFields === false && fields && fields.length > 0 && usersData && usersData.length > 0) {
      console.log("fields", fields);
      console.log("usersData", usersData);
      handleMapFields(fields);
    }

    if (usersData && usersData.length > 0) {
      setSampleData(usersData.slice(0, 2));
    }
  }, [fields, usersData]);

  return (
    <>
      <MDBox mb={2} sx={{ border: "2px solid #ccc", borderRadius: "5px" }}>
        <Grid container display="flex" justifyContent="space-between" p={2}>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h6" fontWeight="medium">
                  Fields in CRM
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDTypography variant="h6" fontWeight="medium">
                  Fields in file
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <MDTypography variant="h6" fontWeight="medium">
              Sample Data from file
            </MDTypography>
          </Grid>
        </Grid>
        <MDBox sx={{ borderBottom: "2px solid #ccc" }}></MDBox>
        <MDBox>
          {mappedFields &&
            Object.entries(mappedFields).map(([key, value]) => (
              <Grid container display="flex" justifyContent="space-between" p={1.5} key={key}>
                <Grid item xs={5}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" style={{fontWeight:"normal"}}>{key} 
                      {checkForRequiredFields(key) && <span style={{ color: "red", marginLeft: 2 }}>*</span>}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        disableClearable
                        options={fileFieldNames}
                        getOptionLabel={(option) => option || null}
                        value={fileFieldNames.find((field) => field === mappedFields[key]) || null}
                        onChange={(event, newValue) => handleChange(event, newValue, key)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Select Field To Import"
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem
                            {...props}
                            key={option}
                            value={option}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <span>{option}</span>
                              {/* {option.is_required === 1 && (
                                <span style={{ color: "red", marginLeft: 4 }}>*</span>
                              )} */}
                            </div>
                          </MenuItem>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" style={{fontWeight:"normal"}}>
                        {SampleData && SampleData[0]?.[mappedFields[key]] ? SampleData[0]?.[mappedFields[key]] : "-"}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" style={{fontWeight:"normal"}}>
                        {SampleData && SampleData[1]?.[mappedFields[key]] ? SampleData[1]?.[mappedFields[key]] : "-"}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </MDBox>
      </MDBox>
      <MDBox
        mt={2}
        p={2}
        position="absolute"
        bottom={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MDButton variant="gradient" color="dark" onClick={handleMoveToNext}>
          Confirm Mapping
        </MDButton>
      </MDBox>
    </>
  );
};

export default MappingFields;
