import { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import ActivityCard from "./Cards/ActivityCard";

const Activity = ({activityLogs, filteredText, setFilteredText, ...props}) => {
  const [filteredActivityData, setFilteredActivityData] = useState(activityLogs);
  const filterButtons = [
    {
      name: "allActivity",
      label: "All activities",
    },
    {
      name: "notes",
      label: "Notes",
    },
    {
      name: "tasks",
      label: "Tasks",
    },
  ];

  useEffect(() => {
    if(filteredText === "allActivity") {
      setFilteredActivityData(activityLogs);
    } else {
      let filteredData = activityLogs.filter((activity) => activity.subject_type === filteredText);
      setFilteredActivityData(filteredData);
    }
    
  }, [filteredText, activityLogs]);

  

  return (
    <Card>
      <MDBox p={2}>
        {props.type !== "History" ? (
          <MDTypography variant="h6" fontWeight="medium">
          Recent history
        </MDTypography>
        ):(
            <MDBox display="flex" alignItems="center">
            {
              filterButtons.map((button) => (
                <MDBox className={`filter-chip cursor-pointer ${filteredText === button.name ? "active" : ""}`} mr={2} key={button.name} onClick={() => setFilteredText(button.name)}>
                  <MDTypography variant="caption" fontWeight="regular" className="cursor-pointer">
                      {button.label}
                  </MDTypography>
              </MDBox>
))
            }
          </MDBox>

        )}
        <ActivityCard {...props} activityLogs={filteredActivityData} setFilteredText={setFilteredText} />
      </MDBox>
    </Card>
  );
};

export default Activity;
