import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import MDTable from "components/MDTables";
import TableContext from "context/Tablecontext";
import DefaultCell from "components/Cell/DefaultCell";
import { changeDateFormatWithTimeZone, toTitleCase, splitNameByComma } from "lib/helper";
import Icon from "@mui/material/Icon";
import ConfirmDelete from "components/ConfirmDelete";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import MDBadge from "components/MDBadge";

// Page Components
import CreateTask from "./components/CreateTask";

// Icons
import { MdCurrencyRupee } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaRegCircleUser } from "react-icons/fa6";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { get } from "draft-js/lib/DefaultDraftBlockRenderMap";

function Tasks() {
  const { verifyToken, profileDetails } = useContext(AuthContext);
  const [activeComponent, setActiveComponent] = useState("list");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { getAllDeals, allOptions, getAllOptions } = useContext(CrmContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [refresh, setRefresh] = useState(0);
  const [taskDetail, setTaskDetail] = useState({});
  const [isTableInitialize, setIsTableInitialize] = useState(false);
  const [optionState, setOptionState] = useState({});

  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
    setPageSize,
    setEntriesPerPage,
  } = useContext(TableContext);

  const [filteringParams, setFilteringParams] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    verifyToken();
    initializeTable();
    setPageSize("100");
    setEntriesPerPage({ entries: ["25", "50", "100", "150", "200"] });
    setIsTableInitialize(true);
  }, []);

  const onClose = () => {
    setActiveComponent("list");
  };

  const deleteTask = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.deletedTask}/${taskDetail?.id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const jsonResponse = await response.json();
      if (response.ok) {
        toast.success(jsonResponse.message);
        setRefresh(!refresh);
        setTaskDetail({});
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteTaskById = async (task) => {
    setTaskDetail(task);
    setShowConfirmDelete(true);
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      await deleteTask();
    }
    setShowConfirmDelete(false);
  };

  const editTask = (task) => {
    setActiveComponent("edit");
    setTaskDetail(task);
  };

  const BadgeCell = ({ value }) => {
    if (value === "-") {
      return <MDBox>-</MDBox>;
    }

    let priorityClass = `status-${value.toLowerCase()}`;
  
    return (
      <MDBadge
        variant="contained"
        className={priorityClass}
        badgeContent={value}
        container
      />
    );
  };
  

  const ChipCell = ({ value, type }) => {
    return value !== "-" ? (
      <MDBox mt={0.25} mb={0.65} display="flex" alignItems="center">
        <Chip
          icon={type === "contacts" ? <FaRegCircleUser /> : <HiOutlineChartSquareBar size={14} />}
          label={value[0]}
          size="medium"
          className="table-chip-bg-color"
        />
        {value.length > 1 && (
          <MDBox ml={1}>
            <MDMenu
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              menuWidth={230}
              triggerElement={
                <Chip
                  label={`+ ${value.length - 1}`}
                  size="small"
                  className="table-chip-bg-color cursor-pointer"
                />
              }
            >
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">{`Additional ${
                  type?.charAt(0).toUpperCase() + type?.slice(1)
                }`}</MDTypography>
              </MDBox>
              {value?.map((name, index) => (
                index !== 0 && <MDBox mt={1}>{name}</MDBox>
              ))}
            </MDMenu>
          </MDBox>
        )}
      </MDBox>
    ) : (
      <MDBox>-</MDBox>
    );
  };

  const ButtonCell = ({ task }) => {
    return (
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MDBox mr={2}>
          <MDBox
            variant=""
            sx={{ display: "flex", alignItems: "center" }}
            color="error"
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              deleteTaskById(task);
            }}
          >
            <Icon>delete</Icon>&nbsp;
          </MDBox>
        </MDBox>
        <MDBox
          mr={2}
          variant="caption"
          sx={{ display: "flex", alignItems: "center" }}
          color="info"
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            editTask(task);
          }}
        >
          <Icon>edit</Icon>&nbsp;
        </MDBox>
      </MDBox>
    );
  };

  const loadData = (taskData) => {
    if (taskData && taskData.length > 0) {
      let columns = [
        {
          Header: "id",
          accessor: "id",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "name",
          accessor: "name",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "priority",
          accessor: "priority",
          Cell: ({ value }) => <BadgeCell value={value} />,
        },
        {
          Header: "due date",
          accessor: "due_date",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "created at",
          accessor: "created_at",
          align: "left",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "contacts",
          accessor: "contacts",
          align: "left",
          Cell: ({ value }) => <ChipCell value={value} type="contacts" />,
        },
        {
          Header: "deals",
          accessor: "deals",
          align: "left",
          Cell: ({ value }) => <ChipCell value={value} type="deals" />,
        },
        {
          Header: "action",
          accessor: "action",
          isSorted: false,
          Cell: ({ value }) => <ButtonCell task={value} />,
        },
      ];

      let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      let rows = taskData.map((task) => {
        const created_at = task?.created_at
          ? changeDateFormatWithTimeZone(task?.created_at, timeZone)
          : "-";
        const due_date = task?.due_date
          ? changeDateFormatWithTimeZone(task?.due_date, timeZone).split(" ")[0]
          : "-";

        const contacts = task?.contact_names ? splitNameByComma(task?.contact_names) : "-";
        const deals = task?.deal_names ? splitNameByComma(task?.deal_names) : "-";

        return {
          id: task.id.toString(),
          name: task.name,
          priority: task.priority ? toTitleCase(task.priority) : "-",
          due_date: due_date,
          contacts: contacts,
          deals: deals,
          created_at: created_at,
          action: task,
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const getTaskLists = async () => {
    try {
      let query = "";

      query += `page=${currentPage}&limit=${pageSize}`;

      if (search) {
        query += `&search=${search}`;
      }

      if (orderBy && order) {
        let sortOrder = order === "asc" ? "ASC" : "DESC";
        query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
      } else {
        query += `&sortBy=due_date&sortOrder=ASC`;
      }

      const response = await fetch(`${API_ENDPOINTS?.getAllTasks}` + `?${query}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const resjson = await response.json();
      if (response.ok) {
        loadData(resjson?.data.items);

        const { data } = resjson;
        const totalPage = Math.ceil(data.totalItems / pageSize);
        const startIndex = (currentPage - 1) * pageSize + 1;
        const endIndex = Math.min(currentPage * pageSize, data.totalItems);
        setEnteries({
          ...enteries,
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          totalMediaCount: totalPage,
          enteriesStart: startIndex,
          enteriesEnd: endIndex,
        });
      } else {
        loadData([]);
      }
    } catch (error) {
      console.log("Error fetching user lists:", error);
    }
  };

  useEffect(() => {

      getTaskLists();

  }, [refresh, filteringParams, pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if(isTableInitialize) {
        getTaskLists();
      }
    }, 500);
    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  return (
    <BaseLayout stickyNavbar>
      <Card id="deals">
        {activeComponent === "list" && (
          <>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">
                  Saved Tasks
                </MDTypography>
              </MDBox>
              <MDBox
                pt={3}
                px={2}
                display="flex"
                alignItems="center"
                sx={{ gap: "7px", padding: "0" }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                  onClick={() => setActiveComponent("create")}
                >
                  Create Task
                </MDButton>
              </MDBox>
            </MDBox>

            <MDBox my={2}>
              <MDTable
                table={dataTable}
                canSearch={true}
                canFilter={true}
                licenceDetails={[]}
              ></MDTable>
            </MDBox>
          </>
        )}
        {activeComponent === "create" && (
          <CreateTask onClose={onClose} handleRefresh={handleRefresh} taskDetail={null} />
        )}
        {activeComponent === "edit" && (
          <CreateTask onClose={onClose} handleRefresh={handleRefresh} taskDetail={taskDetail} />
        )}
      </Card>
      <ConfirmDelete
        title="Delete Task?"
        message="Are you sure you want to delete this Task?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
    </BaseLayout>
  );
}

export default Tasks;
