import { useContext, useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import MDButton from "components/MDButton";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// NewUser page components
import FormField from "components/FormField";

// context
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import dayjs from "dayjs";
import { toTitleCase } from "lib/helper";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import {convertSelectedDateToDayjs} from "lib/helper";

dayjs.extend(utc);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function DealForm({ formData, setFieldValue, activePipeline, showFormInCard }) {
  const { profileDetails } = useContext(AuthContext);
  const { getAllContacts, getAllOptions, allOptions, allPipelines } = useContext(CrmContext);
  const { formField, values, errors, touched } = formData;
  const [stageList, setStageList] = useState([]);
  const { contacts, name, pipeline, stage, amount, closeDate, createTask, type, taskName, dueDate, dueTime } =
    formField;
  const [userContacts, setUserContacts] = useState([]);
  const theme = useTheme();
  const now = dayjs();
  const currentDate = now.format("YYYY-MM-DD");
  const currentTime = now.format("HH:mm");
  const dayjsCurrentDate = dayjs().startOf('day');

  const {
    contacts: contactsV,
    name: nameV,
    pipeline: pipelineV,
    stage: stageV,
    amount: amountV,
    closeDate: closeDateV,
    createTask: createTaskV,
    type: typeV,
    taskName: taskNameV,
    dueDate: dueDateV,
    dueTime: dueTimeV,
  } = values;

  useEffect(()=> {
     if(values?.pipeline) {
       const stages = allPipelines.find((item) => item.id === values?.pipeline)?.stages;
       setStageList(stages);
     }
  }, [values?.pipeline])
  
  const initialDueDate = dueDateV;

  const handleSetTaskName = (name) => {
    if (name) {
      setFieldValue("taskName", name);
    }
  };

  const handleDateSelection = (value, fieldName) => {
    let formattedDate = convertSelectedDateToDayjs(value);
    setFieldValue(fieldName, formattedDate);
  };

  function getStyles(optionId, selectedLists, theme) {
    return {
      fontWeight: selectedLists.includes(optionId)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  useEffect(() => {
    async function fetchValues() {
      const contacts = await getAllContacts();
      setUserContacts(contacts);
      await getAllOptions();
    }
    fetchValues();
  }, []);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              required={name.isRequired}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV?.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: `${contactsV?.length > 0 ? "0px" : "10px"}`,
                "& .MuiInputLabel-root": {
                  marginTop: "-10px",
                },
              }}
            >
              <InputLabel id="demo-multiple-chip-label">{contacts.label}</InputLabel>
              <Select
                sx={{
                  '& .MuiSelect-icon': {
                    display: 'block',
                    width: "1.8em",
                    height: "1.8em",
                    top: "calc(50% - 1.3em)"
                  },
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={contactsV}
                onChange={(e) => setFieldValue("contacts", e.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((id) => {
                      const selectedOption = userContacts.find((option) => option.id == id);
                      return selectedOption ? <Chip key={id} label={selectedOption?.full_name.charAt(0)?.toUpperCase() + selectedOption?.full_name?.slice(1)} /> : null;
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {userContacts.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.id, contactsV, theme)}
                  >
                    {option?.full_name.charAt(0)?.toUpperCase() + option?.full_name?.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {!showFormInCard ? (
            <>
            <Grid item xs={12} sm={6}>
  <Autocomplete
    disableClearable
    options={allPipelines}
    getOptionLabel={(option) => toTitleCase(option.name) || ""}
    isOptionEqualToValue={(option, value) => option.id === value?.id}
    value={allPipelines.find((option) => option.id == pipelineV) || null}
    onChange={(event, value) => {
      setFieldValue("pipeline", value?.id || "");
    }}
    renderInput={(params) => (
      <MDInput
        {...params}
        variant="standard"
        label={pipeline.label}
        required={pipeline.isRequired || false}
      />
    )}
  />
  <MDBox mt={0.75}>
    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
      <ErrorMessage name={pipeline.name} />
    </MDTypography>
  </MDBox>
</Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disableClearable
              options={stageList || []}
              getOptionLabel={(option) => option.value || ""}
              isOptionEqualToValue={(option, value) => option.key == value.key}
              value={stageList?.find((option) => option.key == stageV) || null}
              onChange={(event, value) => {
                setFieldValue("stage", value?.key || "");
              }}
              noOptionsText={stageList?.length ? "No options" : "Please select pipeline first"}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label={stage.label}
                  required={stage.isRequired || false}
                />
              )}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name={stage.name} />
              </MDTypography>
            </MDBox>
          </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={6}>
            <Autocomplete
              disableClearable
              options={activePipeline?.stages || []}
              getOptionLabel={(option) => option.value || ""}
              isOptionEqualToValue={(option, value) => option.key == value.key}
              value={activePipeline?.stages?.find((option) => option.key == stageV) || null}
              onChange={(event, value) => {
                setFieldValue("stage", value?.key || "");
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label={stage.label}
                  required={stage.isRequired || false}
                />
              )}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name={stage.name} />
              </MDTypography>
            </MDBox>
          </Grid>
          )}



          <Grid item xs={12} sm={6}>
            <FormField
              type={amount.type}
              label={amount.label}
              name={amount.name}
              value={amountV}
              placeholder={amount.placeholder}
              error={errors?.amount && touched?.amount}
              success={amountV?.length > 0 && !errors.amount}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={12}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  label={closeDate.label}
                  value={closeDateV ? dayjs(closeDateV).utc() : null}
                  // openTo="year"
                  onChange={(value) => handleDateSelection(value, "closeDate")}
                  minDate={dayjsCurrentDate}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputLabelProps: {
                        shrink: true,
                      },
                      sx: { width: "99%" },
                    },
                  }}
                />
              </DemoContainer>
            </Grid>
          </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
              control={
                <Checkbox
                  checked={createTaskV}
                  onChange={(event) => {
                    setFieldValue("createTask", event.target.checked);
                  }}
                  name={createTask.name}
                />
              }
            />
            <span className="checkbox-label">
                  {createTask.label}
                </span>
        </MDBox>
          </Grid>
        </Grid>
        {createTaskV && (
          <>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                mt: 1.5,
                mb: 1,
              }}
            >
              <MDTypography
                variant="h6"
                fontWeight="medium"
                sx={{ borderBottom: "2px solid #d0d0d0" }}
              >
                Add a Task
              </MDTypography>
            </MDBox>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disableClearable
                  options={allOptions?.types || []}
                  getOptionLabel={(option) => option.value || ""}
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                  value={allOptions?.types?.find((option) => option.key === typeV) || null}
                  onChange={(event, value) => {
                    setFieldValue("type", value?.key || "");
                    handleSetTaskName(value?.value || "");
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label={type.label}
                      required={type.isRequired || false}
                    />
                  )}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name={type.name} />
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={taskName.type}
                  label={taskName.label}
                  name={taskName.name}
                  value={taskNameV}
                  placeholder={taskName.placeholder}
                  error={errors?.taskName && touched?.taskName}
                  success={taskNameV?.length > 0 && !errors.taskName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={12} sm={12}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
                    >
                      <DatePicker
                        views={["year", "month", "day"]}
                        label={dueDate.label}
                        value={dueDateV ? dayjs(dueDateV).utc() : null}
                        onChange={(value) => handleDateSelection(value, "dueDate")}
                        minDate={dayjsCurrentDate}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            InputLabelProps: {
                              shrink: true,
                            },
                            sx: { width: "99%" },
                          },
                        }}
                      />
                    </DemoContainer>
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={dueTime.type}
                  label={dueTime.label}
                  name={dueTime.name}
                  value={dueTimeV}
                  onChange={(event, value) => {
                    if (event) {
                      setFieldValue("dueTime", event.target?.value);
                    }
                  }}
                  placeholder={dueTime.placeholder}
                  error={errors?.dueTime && touched?.dueTime}
                  success={dueTimeV?.length > 0 && !errors.dueTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="custom-time-field"
                />
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for DealForm
DealForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DealForm;
