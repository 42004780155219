/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatDistanceToNow } from "date-fns";
import Chip from "@mui/material/Chip";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { FaRegEdit } from "react-icons/fa";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaRegThumbsDown } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { HiDotsVertical } from "react-icons/hi";
import SidePanelContext from "context/SidePanelContext";
import { FiClipboard } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FiCalendar } from "react-icons/fi";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import { FiClock } from "react-icons/fi";
import { CiLinkedin } from "react-icons/ci";
import { BiDollar } from "react-icons/bi";
import CrmContext from "context/CrmContext";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { toTitleCase } from "lib/helper";

// Custom styles for the Card

function DealCard({ deal, deleteDeal, setActiveTask, setActiveDealOption, updateDeal, setActiveNote, setActiveDeal, setActiveComponent, setActiveContact, setShowDialog, setActiveContactDeal, handleRefresh, setDealContact }) {
  const [openMenu, setOpenMenu] = useState(false);
  const { openPanel } = useContext(SidePanelContext);
  const { activePipeline, updateTask } = useContext(CrmContext);
  const [lastActivityDate, setLastActivityDate] = useState("");

  useEffect(() => {
     if(deal?.updated_at) {
      const activityDate = formatDistanceToNow(new Date(deal.updated_at), { addSuffix: true })
      setLastActivityDate(activityDate)
     }
  }, [deal]);

  const constructDueDateMessage = (dueDateString, dueTimeString) => {
    if (!dueDateString) return null;
  
    const dueDateTime = dueTimeString
      ? new Date(`${dueDateString}T${dueTimeString}`)
      : new Date(dueDateString);
    const currentDate = new Date();
  
    if (!dueTimeString) {
      currentDate.setHours(0, 0, 0, 0);
    }
  
    const timeDifference = dueDateTime - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    if (timeDifference > 0) {
      if (daysDifference > 0) {
        return (
          <MDBox mt={-0.3} ml={0.8}>
            <MDTypography variant="caption" fontWeight="normal" color="text">
              {`in ${daysDifference} day${daysDifference > 1 ? 's' : ''}`}
            </MDTypography>
          </MDBox>
        );
      } else {
        return (
          <MDBox mt={-0.3} ml={0.8}>
            <MDTypography variant="caption" fontWeight="normal" color="text">
              due today
            </MDTypography>
          </MDBox>
        );
      }
    } else if (timeDifference < 0) {
      // Overdue tasks
      return (
        <MDBox mt={-0.3} ml={0.8}>
          <MDTypography variant="caption" fontWeight="normal" style={{ color: "red" }}>
            {`${Math.abs(daysDifference)} day${Math.abs(daysDifference) > 1 ? 's ' : ' '}`}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="medium" style={{ color: "red" }}>
            OVERDUE
          </MDTypography>
        </MDBox>
      );
    } else {
      // Task due now
      return (
        <MDBox mt={-0.3} ml={0.8}>
          <MDTypography variant="caption" fontWeight="normal" color="text">
            due today
          </MDTypography>
        </MDBox>
      );
    }
  };
  
  

  const modifyTaskNames = (tasks) => {
    let updatedName = tasks.map((task) => task.name);
    updatedName.shift();
  
    return updatedName.map((name) => {
      if (!name) return "";
      return name.charAt(0).toUpperCase() + name.slice(1);
    });
  };
  
  const modifyContactNames = (contacts) => {
    if (contacts?.length > 1) {
      const contactList = contacts.slice(1); // Create a new array without mutating the original
      return contactList.map((contact) => ({
        ...contact,
        name: contact?.name?.split(" ")[0].charAt(0).toUpperCase() + contact?.name?.split(" ")[0].slice(1),
      }));
    } else {
      return [];
    }
  };
  

  const handleCardClick = () => {
    if(openMenu) {
      setOpenMenu(false);
      return;
    }
    setActiveComponent("details");
    setActiveDeal(deal);
  }

  const modifyTaskTypeName = (taskType) => {
    if (taskType?.split("_").length > 1) {
      return taskType
        ?.split("_")
        .map((word, index) => {
          if (index == 0) {
            return word?.charAt(0).toUpperCase() + word?.slice(1);
          }
          return word;
        })
        .join("");
    } else {
      return taskType?.charAt(0).toUpperCase() + taskType?.slice(1);
    }
  };

  const stageAvaliableInPipeline = (stage) => {
    if(!activePipeline) {
      return false;
    } 

    if(activePipeline?.stages?.length > 0) {
      return activePipeline?.stages?.some((pipelineStage) => pipelineStage.key === stage);
    }

    return false;
  };

  const updateTaskStatus = async (task) => {
      const isTaskUpdated = await updateTask({ status: task.status === "pending" ? "done" : "pending" }, task.id);
      if(isTaskUpdated) {
        handleRefresh();
      }
  }

  const getTaskIconsBasedonType = (taskType) => {
    if (taskType == "to_do") {
       return <FiClipboard className="theme-text-color" />
    }

    if(taskType == "email") {
      return <MdOutlineEmail className="theme-text-color" />
    }

    if(taskType == "call") {
       return <IoCallOutline className="theme-text-color" />
    }

    if(taskType == "meeting") {
       return <FiCalendar className="theme-text-color" />
    }

    if(taskType == "lunch") {
      return <MdOutlineFreeBreakfast className="theme-text-color" />
    }

    if(taskType == "deadline") {
      return <FiClock className="theme-text-color" />
    }

    if(taskType == "linkedin") {
      return <CiLinkedin className="theme-text-color" />
    }
  }

  const handleAction = (deal, action) => {
    setActiveDealOption(action);
    if (action === "edit-task") {
      openPanel();
      setActiveTask({
        ...deal?.tasks[0],
        deal_ids: [deal.id],
        contact_ids: deal?.contacts?.length > 0 ? deal?.contacts?.map((contact) => contact.id) : [],
      });
      setOpenMenu(false);
    }
    if(action === "mark-done") {
      updateTaskStatus(deal?.tasks[0]);
    }
    if (action === "add-task") {
      let contactIds = deal?.contacts?.length > 0 ? deal?.contacts?.map((contact) => contact.id) : [];
      setDealContact(contactIds);
      openPanel();
      setActiveTask({
        deal_ids: [deal.id],
      });
      setOpenMenu(false);
    }
    if (action === "add-note") {
      openPanel();
      let contactIds = deal?.contacts?.length > 0 ? deal?.contacts?.map((contact) => contact.id) : [];
      setDealContact(contactIds);
      setActiveNote({
        deals: [{id: deal.id}],
      });
      setOpenMenu(false);
    }
    if (action === "mark-won") {
      const { id, name, contacts, tasks, ...rest } = deal;
      updateDeal({ id, name, stage: "won" });
    }
    if (action === "mark-lost") {
      const { id, name, contacts, tasks, ...rest } = deal;
      updateDeal({ id, name, stage: "lost" });
    }
  };

  const MenuOptions = () => {
    return (
      <>
        {deal?.tasks && deal?.tasks?.length > 0 && (
          <MenuItem disableRipple onClick={() => handleAction(deal, "edit-task")}>
            <MDBox mr={1} mt={0.5}>
              <CiEdit size={17} />
            </MDBox>
            Edit Task
          </MenuItem>
        )}
        {deal?.tasks && deal?.tasks?.length > 0 &&
          <MenuItem onClick={() => handleAction(deal, "mark-done")} disableRipple>
          <MDBox mr={1} mt={0.5}>
            <IoIosCheckmarkCircleOutline size={16} />
          </MDBox>
          {deal?.tasks[0].status === "pending" ? "Mark task as Done" : "Re-open task"}
        </MenuItem>
        }
        <MenuItem onClick={() => handleAction(deal, "add-task")} disableRipple>
          <MDBox mr={1} mt={0.5}>
            <IoIosCheckmarkCircleOutline size={16} />
          </MDBox>
          Add a task
        </MenuItem>
        <MenuItem disableRipple onClick={() => handleAction(deal, "add-note")}>
          <MDBox mr={1} mt={0.5}>
            <FaRegEdit />
          </MDBox>
          Add a note
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {stageAvaliableInPipeline('won') && (
          <MenuItem onClick={() => handleAction(deal, "mark-won")} disableRipple>
          <MDBox mr={1} mt={0.5}>
            <FaRegThumbsUp size={14} />
          </MDBox>
          Mark as won
        </MenuItem>
        )}
        {stageAvaliableInPipeline('lost') &&(
          <MenuItem disableRipple onClick={() => handleAction(deal, "mark-lost")}>
          <MDBox mr={1} mt={0.5}>
            <FaRegThumbsDown size={14} />
          </MDBox>
          Mark as lost
        </MenuItem>
        )}
        {(stageAvaliableInPipeline('won') || stageAvaliableInPipeline('lost'))  &&(
          <Divider sx={{ my: 0.5 }} />
        )}
        <MenuItem disableRipple onClick={() => deleteDeal(deal)}>
          <MDBox mr={1} mt={0.5}>
            <MdDeleteOutline size={17} color="red" />
          </MDBox>
          Delete
        </MenuItem>
      </>
    );
  };

  return (
    <>
      <MDBox display="flex" flexDirection="column" pt={1.875} px={1.875} style={{cursor: "pointer"}} onClick={handleCardClick}>
        {deal?.name.length > 25 ?(
          <Tooltip title={deal?.name} placement="bottom">
            <MDTypography variant="subtitle2" fontWeight="regular" className="hide-extra-text">
              {deal?.name.charAt(0).toUpperCase() + deal?.name.slice(1)}
            </MDTypography>
        </Tooltip>
        ):(
          <MDTypography variant="subtitle2" fontWeight="regular" className="hide-extra-text">
              {deal?.name.charAt(0).toUpperCase() + deal?.name.slice(1)}
            </MDTypography>
        )}

        {deal?.amount !== null && deal?.amount != 0 && (
          <MDBox mt={0.9} display="flex" alignItems="center">
            <MDBox mr={-0.2}>
              <BiDollar size={12} color="#a29e9e" />
            </MDBox>
            <MDTypography variant="caption" fontWeight="normal" color="text">
              {deal.amount}
            </MDTypography>
          </MDBox>
        )}
        {deal?.contacts?.length > 0 && (
          <MDBox mt={0.25} mb={0.65} display="flex" alignItems="center">
                <Chip
              icon={<FaRegCircleUser className="theme-text-color" />}
              label={
                deal?.contacts[0]?.name?.split(",")[0].charAt(0).toUpperCase() +
                deal?.contacts[0]?.name?.split(",")[0].slice(1)
              }
              size="medium"
              className="theme-text-color theme-bg-color"
              onClick={(e) => {
          e.stopPropagation();
          setActiveContact(deal?.contacts[0]);
          setShowDialog(true);
          setActiveContactDeal(deal);
        }}
            />
            {deal?.contacts?.length > 1 && (
              <MDBox ml={1}>
                <MDMenu
                  key={deal?.id}
                  openMenu={true}
                  setOpenMenu={setOpenMenu}
                  menuWidth={230}
                  triggerElement={
                    <Chip
                      label={`+ ${deal?.contacts?.length - 1}`}
                      size="small"
                      className="theme-text-color theme-bg-color"
                    />
                  }
                >
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium" className="theme-text-color">
                      Additional Contacts
                    </MDTypography>
                  </MDBox>
                  {modifyContactNames(deal?.contacts)?.map((contact) => (
                    <MDBox key={contact?.id}> 
    {contact?.name.length > 30 ? (
      <Tooltip title={contact?.name} placement="bottom">
      <MDBox className="cursor-pointer list-item hide-extra-text" onClick={
                        (e) => {
                          e.stopPropagation();
                          setActiveContact(contact);
                          setShowDialog(true);
                          setActiveContactDeal(deal);
                        }
                      } mt={1}>{contact?.name}</MDBox>
      </Tooltip>
    ) : (
      <MDBox className="cursor-pointer list-item hide-extra-text" onClick={
                        (e) => {
                          e.stopPropagation();
                          setActiveContact(contact);
                          setShowDialog(true);
                          setActiveContactDeal(deal);
                        }
                      } mt={1}>{contact?.name}</MDBox>
    )}
  </MDBox>

                  ))}
                </MDMenu>
              </MDBox>
            )}
          </MDBox>
        )}

        <MDBox mt={0.25} display="flex" alignItems="center">
          <MDTypography variant="caption" fontWeight="normal" color="text">
            Last Activity:{" "}
            {lastActivityDate 
              ? lastActivityDate
              : "No Activity"}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <MDBox
          mt={1}
          display="flex"
          alignItems="center"
          sx={{ borderBottom: "2px solid #e6e6e6" }}
        ></MDBox>
        {deal?.tasks && deal?.tasks?.length > 0 ? (
          <>
          <MDBox display="flex" flexDirection="column" py={1.25} px={1.875}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

            {deal?.tasks?.length > 0 && (
          <MDBox display="flex" alignItems="center">
            <MDBox>
            <Tooltip title={deal?.tasks[0]?.name} placement="bottom">
            <Chip
              icon={getTaskIconsBasedonType(deal?.tasks[0]?.type)}
              label={
                modifyTaskTypeName(deal?.tasks[0]?.type)
              }
              size="medium"
              className="theme-bg-color theme-text-color"
              onClick={(e) => {
          e.stopPropagation();
        }}
            />
            </Tooltip>
            </MDBox>
            {constructDueDateMessage(deal?.tasks[0]?.due_date, deal?.tasks[0]?.due_time)}
          </MDBox>
        )}

            <MDBox mb={0.65} display="flex" alignItems="center" gap={1}>
              <MDBox>
                <MDMenu
                  key={deal?.id}
                  openMenu={openMenu}
                  setOpenMenu={setOpenMenu}
                  menuWidth={230}
                  triggerElement={
                    <MDBox mb={-0.7}>
                      <HiDotsVertical size={20} />
                    </MDBox>
                  }
                >
                  <MenuOptions />
                </MDMenu>
              </MDBox>
            </MDBox>
          </MDBox>
          {deal?.tasks?.length > 1 && (
              <MDBox>
                <MDMenu
                  key={deal?.id}
                  openMenu={true}
                  setOpenMenu={setOpenMenu}
                  menuWidth={230}
                  triggerElement={
                    <Chip style={{padding: 0}}
                      label={`+ ${deal?.tasks?.length - 1}`}
                      size="small"
                      className="theme-bg-color theme-text-color"
                    />
                  }
                >
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium" className="theme-text-color">
                      Upcoming Tasks
                    </MDTypography>
                  </MDBox>
                  {modifyTaskNames(deal?.tasks)?.map((name, index) => (
  <MDBox key={deal?.tasks[index]?.id || index}> 
    {name.length > 30 ? (
      <Tooltip key={deal?.tasks[index]?.id} title={name} placement="bottom">
        <MDBox mt={1} className="hide-extra-text">{name}</MDBox>
      </Tooltip>
    ) : (
      <MDBox mt={1}>{name}</MDBox>
    )}
  </MDBox>
))}

                </MDMenu>
              </MDBox>
            )}
          </MDBox>
          </>
        ) : (
          <MDMenu
            key={deal?.id}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            menuWidth={230}
            triggerElement={
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="action-wrapper"
                height={40}
              >
                <MDTypography
                  variant="subtitle2"
                  fontWeight="bold"
                  className="action-text"
                >
                  Choose action
                </MDTypography>
              </MDBox>
            }
          >
            <MenuOptions />
          </MDMenu>
        )}
      </MDBox>
    </>
  );
}

export default DealCard;
