import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import Board from "@asseinfo/react-kanban";
// import { moveCard } from "@asseinfo/react-kanban";
import toast from "react-hot-toast";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import parse from "html-react-parser";
import DealCard from "./components/DealCard";
import MDSortingMenu from "components/MDSortingMenu";
import MDDialog from "components/MDDialog";

// Page Components
import CreateDeal from "./components/CreateDeal";
import ConfirmDelete from "components/ConfirmDelete";
import MDSidePanel from "components/MDSidePanel";
import CreateTask from "../tasks/components/CreateTask";
import SidePanelContext from "context/SidePanelContext";
import NoteForm from "../notes/components/NoteForm";
import DealDetails from "./components/DealDetails";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { dealsFilteringField, dealsSortingOrder } from "constants/crm";
import MoveContactToDeal from "./components/MoveContactToDeal";
import { FiPlusCircle } from "react-icons/fi";

// Icons
import { BiDollar } from "react-icons/bi";
import { Tooltip } from "@material-ui/core";

const dialogWidthConfig = {
  xs: "100%",
  sm: "80%",
  md: "70%",
  lg: "40%",
  xl: "30%",
};

function Deals() {
  const { verifyToken } = useContext(AuthContext);
  const [activeComponent, setActiveComponent] = useState("list");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { getAllDeals, allOptions, getAllOptions, getAllPipeline, getAllContacts, activePipeline, setActivePipeline } =
    useContext(CrmContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deals, setDeals] = useState([]);
  const [activeDeal, setActiveDeal] = useState({});
  const [activeTask, setActiveTask] = useState({});
  const [activeDealOption, setActiveDealOption] = useState("");
  const [activeNote, setActiveNote] = useState({});
  const [activeStage, setActiveStage] = useState(null);
  const [boards, setBoards] = useState({
    columns: [],
  });
  const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);
  const [pipelines, setPipelines] = useState([]);
  const [isComponentsUpdated, setIsComponentsUpdated] = useState(false);
  const [sortingField, setSortingField] = useState(dealsFilteringField[2].key);
  const [sortingOrder, setSortingOrder] = useState(dealsSortingOrder[0].key);
  const [sortingOptionForField, setSortingOptionForField] = useState(dealsFilteringField);
  const [sortingOptionForOrder, setSortingOptionForOrder] = useState(dealsSortingOrder);
  const [showDialog, setShowDialog] = useState(false);
  const [activeContact, setActiveContact] = useState(null);
  const [activeContactDeal, setActiveContactDeal] = useState(null);
  const [dealContact, setDealContact] = useState([]);


  const navigate = useNavigate();

  // dialog
  const handleCloseDialog = () => {
    setShowDialog(false);
    setActiveContact(null);
    setActiveContactDeal(null);
  };

  const handleChangePipeline = (pipeline) => {
    setActivePipeline(pipeline);
    localStorage.setItem("activePipeline", JSON.stringify(pipeline));
  };

  const onClose = () => {
    setActiveComponent("list");
    setActiveDealOption("");
    setActiveStage(null);
    setDealContact([]);
  };

  const handleRefresh = () => {
    initializeColumns();
    if (isPanelOpen) {
      closePanel();
    }
    setActiveComponent("list");
  };

  const updateDeal = async (deal, refreshOnUpdate = true) => {
    if (deal?.id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.updateDeal}/${deal?.id}`, {
          method: "PUT",
          body: JSON.stringify({ name: deal?.name, stage: deal?.stage }),
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          if (refreshOnUpdate) {
            handleRefresh();
          }
          toast.success(responseData?.message || "Deal Updated Successfully");
        } else {
          throw new Error(responseData?.error || "Some error occurred while updating deal");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const initializeCards = (columnsData, deals) => {
    let columns = columnsData;
    deals.forEach((deal) => {
      let stage = deal.stage;
      let index = columns.findIndex((column) => column.id === stage);

      if (index !== -1) {
        const totalItems = columns[index].total_item + 1;
        const totalAmount = columns[index].total_amount + deal.amount;

        columns[index] = {
          ...columns[index],
          total_item: totalItems,
          total_amount: totalAmount,
          cards: [
            ...columns[index].cards,
            {
              id: deal.id,
              template: (
                <DealCard
                  deal={deal}
                  deleteDeal={deleteDeal}
                  setActiveTask={setActiveTask}
                  setActiveDealOption={setActiveDealOption}
                  updateDeal={updateDeal}
                  setActiveNote={setActiveNote}
                  setActiveDeal={setActiveDeal}
                  setActiveComponent={setActiveComponent}
                  setActiveContact={setActiveContact}
                  setShowDialog={setShowDialog}
                  setActiveContactDeal={setActiveContactDeal}
                  handleRefresh={handleRefresh}
                  setDealContact={setDealContact}
                />
              ),
            },
          ],
        };
      }
    });
    setBoards({ columns });
  };

  const initializeColumns = async () => {
    let columns = [];

    activePipeline?.stages.forEach(({ key, value, probability_percentage }, index) => {
      columns.push({
        id: key,
        title: value,
        total_item: 0,
        total_amount: 0,
        probability_percentage,
        cards: [],
      });
    });

    setBoards({ columns });

    let sortingQuery = "";
    if (sortingField) {
      sortingQuery = `sort=${sortingField}&order=${sortingOrder}`;
    }
      
    const dealsData = await getAllDeals({
      includeRelatedData: true,
      pipelineId: activePipeline?.id,
      sortingQuery,
    });
    setDeals(dealsData);
    initializeCards(columns, dealsData);
  };

  const fetchAllPipelines = async () => {
    const pipelines = await getAllPipeline();
    if(pipelines?.length > 0){
        const filterPipeline = pipelines?.find((pipeline) => pipeline?.is_default == true);
        let activePipelineinStorage = JSON.parse(localStorage.getItem("activePipeline"));
        if(activePipelineinStorage === null) {
          if(filterPipeline) {
            setActivePipeline(filterPipeline);
          } else {
            setActivePipeline(pipelines[0]);
          }
        } else {
          const filterStoragePipeline = pipelines?.find((pipeline) => pipeline?.id == activePipelineinStorage?.id);
          if(filterStoragePipeline) {
            setActivePipeline(filterStoragePipeline);
          } else {
            if(filterPipeline) {
              setActivePipeline(filterPipeline);
            } else {
              setActivePipeline(pipelines[0]);
            }
          }
        }
      setPipelines(pipelines);
    } else {
      setPipelines([]);
      setActivePipeline(null);
    }
  };

  useEffect(() => {
    verifyToken();
    fetchAllPipelines();
    getAllContacts();
  }, []);

  useEffect(() => {
    if (activePipeline && Object.keys(activePipeline).length > 0) {
      initializeColumns();
    }
    if (Object.keys(allOptions).length == 0) {
      getAllOptions();
    }
  }, [activePipeline]);

  useEffect(() => {
    if (activePipeline && Object.keys(activePipeline).length > 0) {
      initializeColumns();
    }
  }, [sortingField, sortingOrder]);

  useEffect(() => {
    if (activeComponent == "list") {
       if(isComponentsUpdated){
        handleRefresh();
        setIsComponentsUpdated(false);
       }
    }
  }, [activeComponent]);

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm && activeDeal?.id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.deleteDeal}/${activeDeal?.id}`, {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          handleRefresh();
          toast.success(responseData?.message || "Deal Deleted Successfully");
        } else {
          throw new Error(responseData?.error || "Some error occurred while deleting deal");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    setActiveDeal({});
    setShowConfirmDelete(false);
  };

  const deleteDeal = async (deal) => {
    setActiveDeal(deal);
    setShowConfirmDelete(true);
  };

  const moveCard = (board, source, destination) => {
    const { columns } = board;

    // Find source and destination columns
    const sourceColumn = columns.find((col) => col.id === source.fromColumnId);
    const destinationColumn = columns.find((col) => col.id === destination.toColumnId);

    if (!sourceColumn || !destinationColumn) {
      console.error("Source or destination column not found!");
      return board;
    }

    // Safeguard for undefined cards
    const sourceCards = sourceColumn.cards || [];
    const destinationCards = destinationColumn.cards || [];

    // Remove card from source
    const [cardToMove] = sourceCards.splice(source.fromPosition, 1);

    if (!cardToMove) {
      console.error("No card found to move!");
      return board; // Exit if no card is found
    }

    const dealToUpdate = cardToMove.template.props.deal;
    updateDeal(
      { id: dealToUpdate.id, stage: destination.toColumnId, name: dealToUpdate.name },
      true
    );

    // Insert card into destination
    destinationCards.splice(destination.toPosition, 0, cardToMove);

    // Update columns
    const updatedColumns = columns.map((column) => {
      if (column.id === source.fromColumnId) {
        return {
          ...column,
          cards: sourceCards,
          total_item: sourceCards.length,
          total_amount: sourceCards.reduce(
            (sum, card) => sum + (card?.template?.props?.deal?.amount || 0),
            0
          ),
        };
      }
      if (column.id === destination.toColumnId) {
        return {
          ...column,
          cards: destinationCards,
          total_item: destinationCards.length,
          total_amount: destinationCards.reduce(
            (sum, card) => sum + (card?.template?.props?.deal?.amount || 0),
            0
          ),
        };
      }
      return column;
    });

    return { columns: updatedColumns };
  };

  return (
    <BaseLayout stickyNavbar>
      {activeComponent === "list" && (
        <>
        <Card id="deals">
          <MDBox display="flex" flexDirection="column" p={2}>
          <MDBox display="flex" alignItems="center" justifyContent="space-between">
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium">
                Saved Deals
              </MDTypography>
            </MDBox>
            {pipelines?.length > 0 && (
              <MDBox
                pt={3}
                px={2}
                display="flex"
                alignItems="center"
                sx={{ gap: "7px", padding: "0" }}
              >
                <MDTypography variant="caption" color="secondary" fontWeight="regular" className="text-no-wrap">
                  Pipelines&nbsp;&nbsp;
                </MDTypography>
                <MDBox>
                  <FormControl sx={{ m: 1, minWidth: 180 }}>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      placeholder="Select Pipeline"
                      value={activePipeline?.id || ""}
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        const pipeline = pipelines.find((p) => p.id === selectedId);
                        handleChangePipeline(pipeline);
                      }}
                      autoWidth
                      sx={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        "& .MuiSelect-icon": {
                          display: "block",
                          display: "block",
                          width: "1.8em",
                          height: "1.8em",
                          top: "calc(50% - 1em)",
                        },
                      }}
                    >
                      {pipelines.map((pipeline) => (
                        <MenuItem key={pipeline.id} value={pipeline.id}>
                          {pipeline.name} {/* Display pipeline name */}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                  onClick={() => setActiveComponent("create")}
                >
                  Create Deal
                </MDButton>
              </MDBox>
            )}
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="flex-end">
              <MDBox
                px={2}
                display="flex"
                alignItems="center"
                sx={{ gap: "7px", padding: "0" }}
              >
                <MDSortingMenu sortingParams={sortingField} setSortingParams={setSortingField} sortingOptions={sortingOptionForField} defaultTitle={"Sort By Field"} />
                  <MDSortingMenu sortingParams={sortingOrder} setSortingParams={setSortingOrder} sortingOptions={sortingOptionForOrder} defaultTitle={"Sort By Order"} disabled={!sortingField} />
              </MDBox>
          </MDBox>
          </MDBox>

          {pipelines?.length > 0 ? (
            <MDBox
              position="relative"
              my={4}
              sx={({
                palette: { light, background },
                functions: { pxToRem },
                borders: { borderRadius },
              }) => ({
                "& .react-kanban-column": {
                  backgroundColor: darkMode ? background.card : light.main,
                  width: pxToRem(280),
                  margin: `0 ${pxToRem(10)}`,
                  padding: pxToRem(10),
                  borderRadius: borderRadius.lg,
                },
              })}
            >
              <Board
                allowAddCard
                allowAddColumn
                onCardDragEnd={(card, source, destination) => {
                  if (!destination) {
                    return;
                  }
                  try {
                    const updatedBoard = moveCard(boards, source, destination);
                    setBoards(updatedBoard);
                    // initializeColumns();
                  } catch (error) {
                    console.error("Error while moving card:", error);
                  }
                }}
                renderColumnHeader={({
                  id,
                  title,
                  total_item,
                  total_amount,
                  probability_percentage,
                }) => {
                  return (
                    <>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={0.5}
                        key={id}
                      >
                        <MDBox display="flex" alignItems="center" gap={1}>
                        <MDTypography
                          variant="h6"
                          style={{
                            color: id === "won" ? "#0E9A71" : id === "lost" ? "#FF0000" : "",
                          }}
                        >{`${title} (${probability_percentage}%)`}</MDTypography>
                        <Tooltip title={"Create Deal"} placement="bottom">
                        <FiPlusCircle size={15} className="cursor-pointer margin-t-0-2" onClick={() => {
                          setActiveStage(id);
                          setActiveComponent("create")
                        }}/>
                        </Tooltip>
                        </MDBox>
                        <MDTypography variant="h6">{total_item}</MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h6" sx={{ color: "#7B7B7B" }}>
                          Total Amount
                        </MDTypography>
                        <MDBox display="flex" alignItems="center">
                          <MDBox mr={-0.2}>
                            <BiDollar size={15} color="#7B7B7B" />
                          </MDBox>
                          <MDTypography variant="h6" sx={{ color: "#7B7B7B" }}>
                            {total_amount}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox sx={{ borderBottom: "2px solid #7B7B7B", mt: 0.3 }}></MDBox>
                    </>
                  );
                }}
                renderCard={({ id, template }, { dragging }) => {
                  if (template) {
                    return (
                      <MDBox
                        key={id}
                        dragging={dragging.toString() || undefined}
                        display="block"
                        width="calc(280px - 20px)"
                        bgColor={darkMode ? "transparent" : "white"}
                        color="text"
                        borderRadius="xl"
                        mt={2.5}
                        lineHeight={1.5}
                        sx={{
                          border: ({ borders: { borderWidth }, palette: { white } }) =>
                            darkMode ? `${borderWidth[1]} solid ${white.main}` : 0,
                          fontSize: ({ typography: { size } }) => size.md,
                        }}
                      >
                        {typeof template === "string" ? parse(template) : template}
                      </MDBox>
                    );
                  }
                }}
              >
                {boards}
              </Board>
            </MDBox>
          ) : (
            <MDBox
              py={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <MDTypography variant="h6" color="secondary" fontWeight="regular">
                Start by creating your first pipeline to organize your workflow efficiently!
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ fontSize: "11px", mt: { xs: 3, md: 0 }, mt: 2 }}
                onClick={() => navigate("/pages/crm/pipelines")}
              >
                Create Pipeline
              </MDButton>
            </MDBox>
          )}
        </Card>
         </>
      )}

      {activeComponent === "create" && (
        <CreateDeal
          onClose={onClose}
          handleRefresh={handleRefresh}
          deleteDeal={deleteDeal}
          setActiveDeal={setActiveDeal}
          activePipeline={activePipeline}
          activeStage={activeStage}
        />
      )}
      {activeDealOption === "add-task" && (
        <MDSidePanel header="Add Task" onClose={onClose}>
          <CreateTask
            onClose={onClose}
            handleRefresh={handleRefresh}
            taskDetail={null}
            showFormInCard={false}
            dealIds={activeTask?.deal_ids}
            dealContact={dealContact}
          />
        </MDSidePanel>
      )}
      {activeDealOption === "edit-task" && (
        <MDSidePanel header="Edit Task" onClose={onClose}>
          <CreateTask
            onClose={onClose}
            handleRefresh={handleRefresh}
            taskDetail={activeTask}
            showFormInCard={false}
            dealIds={activeTask.deal_ids}
            type="edit from deal"
          />
        </MDSidePanel>
      )}
      {activeDealOption === "add-note" && (
        <MDSidePanel header="Create Note" onClose={onClose}>
          <NoteForm
            onClose={onClose}
            handleRefresh={handleRefresh}
            noteDetail={activeNote}
            showFormInCard={false}
            dealContact={dealContact}
            showNumberofRows={5}
          />
        </MDSidePanel>
      )}

      {activeComponent === "details" && (
        <DealDetails
          onClose={onClose}
          dealId={activeDeal.id}
          allOptions={allOptions}
          activePipeline={activePipeline}
          handlePageRefresh={handleRefresh}
          deleteDeal={deleteDeal}
          setIsComponentsUpdated={setIsComponentsUpdated}
          deals={deals}
        />
      )}

      {showDialog && (<MDDialog open={showDialog} onClose={handleCloseDialog} title={`Move ${activeContact.name} to other deal(s)`} widthConfig={dialogWidthConfig}>
           <MoveContactToDeal activeContact={activeContact} handleClose={handleCloseDialog} deals={deals} activeContactDeal={activeContactDeal} handleRefresh={handleRefresh} />
      </MDDialog>)}

      <ConfirmDelete
        title="Delete Deal?"
        message="Are you sure you want to delete this deal?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
    </BaseLayout>
  );
}

export default Deals;
