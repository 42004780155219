import React, { useState, Fragment, useEffect, act } from "react";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";

const MoveContactToDeal = ({activeContact, deals, handleClose, activeContactDeal, handleRefresh}) => {
    const [selectedDeals, setSelectedDeals] = useState([]);
    const [filteredDeals, setFilteredDeals] = useState([]);

    useEffect(() => {
        console.log("selectedDeals", selectedDeals);
    }, [selectedDeals])

    const addDeal = (id)=> {
         if(selectedDeals.some((item) => item === id)){
             setSelectedDeals(selectedDeals.filter((item) => item !== id))
         }else{
             setSelectedDeals([...selectedDeals, id])
         }
         }

         const handleSave = async () => {
            try {

                if(!selectedDeals || selectedDeals?.length === 0){
                    toast.error("Please select at least one deal");
                    return;
                } 

                let response = await fetch(API_ENDPOINTS.moveContactFromOneDealToAnother, {
                    method: "PUT",
                    body: JSON.stringify({
                        user_contact_id: activeContact?.id,
                        remove_deal_id: activeContactDeal?.id,
                        add_deal_ids: JSON.stringify(selectedDeals)
                    }),
                    headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
                });

                const responseData = await response.json();
                if (response.ok) {
                    toast.success(responseData?.message);
                    handleClose();
                    handleRefresh();
                } else {
                    toast.error(responseData?.error);
                }
            } catch (error) {  
                console.log("error is", error); 
            }
         };

    useEffect(() => {
        if(deals && deals?.length > 0 && activeContactDeal){
            const filteredDeals = deals.filter((item) => item?.id !== activeContactDeal?.id);
            setFilteredDeals(filteredDeals);
        }
    }, [deals, activeContactDeal])



    return (
       <MDBox ml={2} mt={2} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2} className="folder-list">
                {filteredDeals && filteredDeals?.length > 0 ? (
                    filteredDeals.map((item, index) => {
                        const isSelected = selectedDeals.some((id) => id == item?.id);
                        return (
                            (
                    <Grid item xs={6} sm={6} md={4} lg={4} key={item.id}>
                      {(item.name?.length > 15 ? (
                        <Tooltip title={item?.name?.toUpperCase()} placement="bottom">
                        <MDButton
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          overflowX: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          px: 2,
                        }}
                        variant={isSelected ? "contained" : "outlined"}
                        color="info"
                        fullWidth
                        className={`flex-ellipsis border-1 cursor-pointer folder-box ${
                          isSelected ? "active" : ""
                        } `}
                        p={1}
                        onClick={() => addDeal(item?.id)}
                      >
                        {item?.name?.slice(0, 15) + "..." }
                      </MDButton>
                      </Tooltip>
                      ): (
                        <MDButton
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          overflowX: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          px: 2,
                        }}
                        variant={isSelected ? "contained" : "outlined"}
                        color="info"
                        fullWidth
                        className={`flex-ellipsis border-1 cursor-pointer folder-box ${
                          isSelected ? "active" : ""
                        } `}
                        p={1}
                        onClick={() => addDeal(item?.id)}
                      >
                        {item?.name}
                      </MDButton>
                      ))}
                    </Grid>
                  )
                        )
                    })
                ) : (
                  <Grid>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        No Deals Found
                      </MDTypography>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
       <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mr: 0.8 }}>

       <MDButton color="dark" variant="gradient" type="button" onClick={handleSave}>
                    Save
                  </MDButton>
       </MDBox>
       </MDBox>
    )

}

export default MoveContactToDeal