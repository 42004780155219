import { useContext, useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import MDButton from "components/MDButton";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// NewUser page components
import FormField from "components/FormField";

// context
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { options } from "constants/activityLog";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { useTheme } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import { RxCross2 } from "react-icons/rx";
import TextField from "@mui/material/TextField";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import {convertSelectedDateToDayjs} from "lib/helper";
 
dayjs.extend(utc);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TaskForm = ({ formData, setFieldValue, showFormInCard }) => {
  const { profileDetails } = useContext(AuthContext);
  const { getAllContacts, getAllOptions, allOptions, getAllDeals } = useContext(CrmContext);
  const { formField, values, errors, touched } = formData;
  const { type, name, dueDate, time, priority, notes, contacts, deals } = formField;
  const [userContacts, setUserContacts] = useState([]);
  const [dealsData, setDealsData] = useState([]);
  const theme = useTheme();
  const now = dayjs();
  const currentDate = dayjs().startOf('day');
  const currentTime = now.format("HH:mm");

  const {
    type: typeV,
    name: nameV,
    dueDate: dueDateV,
    time: timeV,
    priority: priorityV,
    notes: notesV,
    contacts: contactsV,
    deals: dealsV,
  } = values;

  const initialDueDate = dueDateV;

  const handleSetName = (name) => {
    if (name) {
      setFieldValue("name", name);
    }
  };

  const handleDateSelection = (value) => {
    let formattedDate = convertSelectedDateToDayjs(value);
    setFieldValue("dueDate", formattedDate);
  };

  const handleRemove = (event, name, id) => {
    if (values[name]) {
      const newValues = values[name].filter((item) => item !== id);
      setFieldValue(name, newValues);
    }
  };

  function getStyles(optionId, selectedLists, theme) {
    return {
      fontWeight: selectedLists.includes(optionId)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  useEffect(() => {
    async function fetchValues() {
      const contacts = await getAllContacts();
      setUserContacts(contacts);
      if (Object.keys(allOptions).length == 0) {
        await getAllOptions();
      }

      const dealsData = await getAllDeals({ includeRelatedData: false });
      setDealsData(dealsData);
    }
    fetchValues();
  }, []);

  return (
    <MDBox>
      <MDBox mt={showFormInCard ? 1.625 : 0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disableClearable
              options={allOptions?.types || []}
              getOptionLabel={(option) => option.value || ""} // Use `value` for display
              isOptionEqualToValue={(option, value) => option.key === value.key} // Compare based on `key`
              value={allOptions?.types?.find((option) => option.key === typeV) || null}
              onChange={(event, value) => {
                setFieldValue("type", value?.key || "");
                handleSetName(value?.value || "");
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label={type.label}
                  required={type.isRequired || false}
                />
              )}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name={type.name} />
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors?.name && touched?.name}
              success={nameV?.length > 0 && !errors.name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>

           <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={12}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  label={dueDate.label}
                  value={dueDateV ? dayjs(dueDateV).utc() : null}
                  // openTo="year"
                  onChange={(value) => handleDateSelection(value)}
                  minDate={currentDate}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputLabelProps: {
                        shrink: true,
                      },
                      sx: { width: "99%" },
                    },
                  }}
                />
              </DemoContainer>
            </Grid>
          </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={time.type}
              label={time.label}
              name={time.name}
              value={timeV}
              onChange={(event, value) => {
                if (event) {
                  setFieldValue("time", event.target?.value);
                }
              }}
              placeholder={time.placeholder}
              error={errors?.time && touched?.time}
              success={timeV?.length > 0 && !errors.time}
              InputLabelProps={{
                shrink: true,
              }}
              className="custom-time-field"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
              <Autocomplete
              disableClearable
              options={allOptions?.priorities || []}
              getOptionLabel={(option) => option.value || ""} // Use `value` for display
              isOptionEqualToValue={(option, value) => option.key === value.key} // Compare based on `key`
              value={allOptions?.priorities?.find((option) => option.key === priorityV) || null}
              onChange={(event, value) => {
                setFieldValue("priority", value?.key || "");
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label={priority.label}
                />
              )}
            />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    marginTop: `${contactsV?.length > 0 ? "0px" : "10px"}`,
                    "& .MuiInputLabel-root": {
                      marginTop: "-10px",
                    },
                  }}
                >
                  <InputLabel id="demo-multiple-chip-label">{contacts.label}</InputLabel>
                  <Select
                    sx={{
                      "& .MuiSelect-icon": {
                        display: "block",
                        width: "1.8em",
                        height: "1.8em",
                        top: "calc(50% - 1.3em)",
                      },
                    }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={contactsV}
                    onChange={(e) => setFieldValue("contacts", e.target.value)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((id) => {
                          const selectedOption = userContacts.find((option) => option.id == id);
                          return selectedOption ? (
                            <Chip
                              key={id}
                              label={
                                selectedOption?.full_name.charAt(0)?.toUpperCase() +
                                selectedOption?.full_name?.slice(1)
                              }
                            />
                          ) : null;
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {userContacts.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        style={getStyles(option.id, contactsV, theme)}
                      >
                        {option?.full_name.charAt(0)?.toUpperCase() + option?.full_name?.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {showFormInCard && (
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                      marginTop: `${dealsV && dealsV?.length > 0 ? "0px" : "10px"}`,
                      "& .MuiInputLabel-root": {
                        marginTop: "-10px",
                      },
                    }}
                  >
                    <InputLabel id="demo-multiple-chip-label">{deals.label}</InputLabel>
                    <Select
                      sx={{
                        "& .MuiSelect-icon": {
                          display: "block",
                          width: "1.8em",
                          height: "1.8em",
                          top: "calc(50% - 1.3em)",
                        },
                      }}
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={dealsV}
                      onChange={(e) => setFieldValue("deals", e.target.value)}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((id) => {
                            const selectedOption = dealsData?.find((option) => option.id == id);
                            return selectedOption ? (
                              <Chip
                                key={id}
                                label={
                                  selectedOption?.name.charAt(0)?.toUpperCase() +
                                  selectedOption?.name?.slice(1)
                                }
                              />
                            ) : null;
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {dealsData?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          style={getStyles(option.id, contactsV, theme)}
                        >
                          {option?.name.charAt(0)?.toUpperCase() + option?.name?.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              id={notes.name}
              label={notes.label}
              name={notes.name}
              multiline
              rows={showFormInCard ? 7 : 5}
              variant="standard"
              fullWidth
              value={notesV}
              onChange={(event, value) => {
                setFieldValue("notes", event.target?.value);
              }}
              error={errors?.notes && touched?.notes}
              success={notesV?.length > 0 && !errors.notes}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default TaskForm;
