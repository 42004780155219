import { useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import API_ENDPOINTS from "apiConfig";

// formik components
import { Formik, Form } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds
import validations from "../Schemas/validation";
import form from "../Schemas/form";
import { useMaterialUIController } from "context";
import Divider from '@mui/material/Divider';

// page component
import ContactFields from "../ContactFields";


// Generate initial values dynamically
const generateInitialValues = (fields) => {
    const initialValues = {};
    fields.forEach((field) => {
        initialValues[field.name] = ""; 
    });
    return initialValues;
};

function ContactForm({ userDetail, onClose, isEditing, fields, customFields, defaultFields, showFormInCard=true, handleRefresh, setDefaultFields }) {
    const { formId, formField } = form;
    const currentValidation = validations[0];
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    // Initialize initial values
    // const initialValues = generateInitialValues(fields);
    const [formValues, setFormValues] = useState(generateInitialValues(fields));

    const sleep = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    // UseEffect to set initial values dynamically if userDetail is present
    useEffect(() => {

        const readUserListContacts = async () => {
            try {
                const response = await fetch(`${API_ENDPOINTS?.getContact}` + `/${userDetail.id}/list-contacts`,{
                    method: "GET",
                    headers: {
                      "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                      "Content-type": "application/json; charset=UTF-8"
                    },
                  })
                const jsonResponse = await response.json();
                if(response.ok){
                    return jsonResponse?.data;
                } else {
                    return [];
                      }
            } catch (error) {
                console.log(error);
            }
        }

        const fetchAndInitializeData = async () => {
            try {
                let userListContacts = [];
            if(userDetail?.id) {
                userListContacts = await readUserListContacts();
            }

            if (userDetail !== null) {
                const customData = JSON.parse(userDetail['custom_data'])
                const updatedValues = { ...formValues };
                fields.forEach((field, index) => {
    
                    const key = field.name;
    
                    if (userDetail.hasOwnProperty(key)) {
                        if(key === 'lists') {
                            updatedValues[key] = JSON.stringify(userListContacts || []);
                        } else {
                            updatedValues[key] = userDetail[key];
                        }
                    } else if (customData) {
                        const exist = customData.find((item) => key in item);
                        if(exist) {
                            updatedValues[key] = exist[key]
    
                        }
                    }
                    
                });

                if (userDetail?.linkedin_url && defaultFields?.length > 0) {
                    const index = defaultFields.findIndex((item) => item.name === 'work_email');
                    if (index !== -1) {
                        const updatedDefaultFields = [...defaultFields];
                        updatedDefaultFields.splice(index, 1, { ...defaultFields[index], is_required: 0 });
                        setDefaultFields(updatedDefaultFields);
                    }
                }
                setFormValues(updatedValues);
            }
            } catch(error) {
                console.log(error);
            }
        }

        fetchAndInitializeData();
    }, [userDetail]);

    const handleSave = async (values) => {
        try{
            let response;
            const payload = {};
            const customData = []
            // Build payload dynamically
            fields.forEach((field, index) => {
              const key = field.name;
              if (field?.is_custom) {
                let obj;
                if (values && values.hasOwnProperty(index)) {
                    obj = {
                        [key]: values[index]
                    }
                  } else if (values && values.hasOwnProperty(key)) {
                    obj = {
                        [key]: values[key]
                    }
                  }
                customData.push(obj);   
              } else {
                if (values && values.hasOwnProperty(index)) {
                    if(values[index] === undefined || values[index] === '') {
                        payload[key] = null
                    } else {
                        payload[key] = values[index];
                    }
                    
                } else if (values && values.hasOwnProperty(key)) {
                    if(values[key] === undefined || values[key] === '') {
                        payload[key] = null
                    } else {
                        payload[key] = values[key];
                    }

                }
              }
            });
            payload['custom_data'] = JSON.stringify(customData);

            if (isEditing) {
                response = await fetch(`${API_ENDPOINTS?.updateContact}/${userDetail.id}`,{
                    method: "PUT",
                    headers: {
                      "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                      "Content-type": "application/json; charset=UTF-8"
                    },
                    body: JSON.stringify({
                        ...payload,
                      }),
                  })
            } else {
                response = await fetch(`${API_ENDPOINTS?.createContact}`,{
                    method: "POST",
                    headers: {
                      "authorization": `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
                      "Content-type": "application/json; charset=UTF-8"
                    },
                    body: JSON.stringify(payload),
                  })
            }
          
          const jsonResponse = await response.json();
          if(response.ok){
            toast.success(jsonResponse.message);
            onClose();
            if(!showFormInCard) { 
                handleRefresh();
            }
          }
          else{
            throw new Error(jsonResponse.message);
          }
        }catch(err){
          toast.error(err.message);
        }
    };

    const submitForm = async (values, actions) => {
        await sleep(1000);
        handleSave(values);
        actions.setSubmitting(true);
    };

    const handleSubmit = (values, actions, errors) => {
        submitForm(values, actions);
        actions.setTouched({});
        actions.setSubmitting(false);
    };

    return (
        <MDBox py={3} mb={20} height="auto">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={!showFormInCard ? 12 : 8}>
                    <Formik
                        initialValues={formValues}
                        validationSchema={currentValidation}
                        enableReinitialize={true} 
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: "100%" }} className={`${!showFormInCard && "disable-card-style"}`}>
                                    <MDBox p={3}>
                                        { showFormInCard && <MDBox
                                            lineHeight={0}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MDTypography variant="h5">{userDetail !== null ? 'Edit Contact Info' : 'Create Contact Info'}</MDTypography>
                                            <MDButton
                                                variant="gradient"
                                                color="dark"
                                                size="small"
                                                onClick={() => onClose()}
                                            >
                                                <Icon>arrow_back</Icon>&nbsp;Back
                                            </MDButton>
                                        </MDBox>}
                                        <ContactFields
                                            formData={{ values, touched, formField, errors }}
                                            setFieldValue={setFieldValue}
                                            userId={userDetail?.id}
                                            fields={defaultFields}
                                        />

                                        {customFields?.length > 0 && (
                                            <>
                                                <MDBox sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", my:4 }}>
                                                    <MDTypography variant="h6" fontWeight="medium" sx={{borderBottom:"2px solid #d0d0d0"}}>
                                                        Custom Fields
                                                    </MDTypography>
                                                </MDBox>

                                                <ContactFields
                                                    formData={{ values, touched, formField, errors }}
                                                    setFieldValue={setFieldValue}
                                                    userId={userDetail?.id}
                                                    fields={customFields}
                                                />
                                            </>
                                        )}
                                        <MDBox>
                                            <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <MDButton
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="dark"
                                                >
                                                    Save
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </MDBox>
    );
}

ContactForm.propTypes = {
    userDetail: PropTypes.object,
    onRefresh: PropTypes.func,
    onClose: PropTypes.func,
    fields: PropTypes.array.isRequired,
};

ContactForm.defaultProps = {
    showFormInCard: true,
    handleRefresh: () => {},
};

export default ContactForm;
