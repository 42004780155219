import { useState, useEffect, useContext, useRef } from "react";
import API_ENDPOINTS from "apiConfig";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { FiEdit } from "react-icons/fi";
import { MdOutlineTaskAlt } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaRegThumbsDown } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa6";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Avatar from "react-avatar";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { TbArrowBackUp } from "react-icons/tb";

import { Tooltip } from "@material-ui/core";
import { toTitleCase } from "lib/helper";

import MDSidePanel from "components/MDSidePanel";
import CreateTask from "../../../tasks/components/CreateTask";
import NoteForm from "../../../notes/components/NoteForm";

// utililities
import moment from "moment";
import { filteredActivityData } from "lib/helper";

// context
import AuthContext from "context/Authcontext";
import SidePanelContext from "context/SidePanelContext";
import CrmContext from "context/CrmContext";
import NotesInfo from "./components/NotesInfo";
import TasksInfo from "./components/TasksInfo";
import DealsInfo from "./components/DealsInfo";
import ConfirmDelete from "components/ConfirmDelete";

// page comoponents
import ContactInfo from "./components/ContactInfo";
import Activity from "../../../deals/components/DealDetails/components/Activity";
import { func } from "prop-types";
import ContactForm from "../ContactForm";
const tabs = ["overview", "history", "tasks", "deals"];

const ContactDetails = ({ activeContact, onClose, customFields, defaultFields, deleteContactById, fields, setActiveContact }) => {
    const [activeTab, setActiveTab] = useState("overview");
    const [contactsActivity, setContactsActivity] = useState([]);
    const {allOptions, getAllOptions, deleteNote, deleteTask, fetchUserContactsById} = useContext(CrmContext);
    const [activeTask, setActiveTask] = useState({});
    const [activeNote, setActiveNote] = useState({});
    const [activeDeal, setActiveDeal] = useState(null);
    const [showTaskConfirmDelete, setShowTaskConfirmDelete] = useState(false);
    const [showNoteConfirmDelete, setShowNoteConfirmDelete] = useState(false);
    const [showForm, setShowForm] = useState("");
    const [filteredText, setFilteredText] = useState("allActivity");

    const { profileDetails } = useContext(AuthContext);
    const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);
  
    const handleSetTabValue = (event, newValue) => {
      if(newValue === 0) setActiveTab("overview");
      if(newValue === 1) setActiveTab("history");
      if(newValue === 2) setActiveTab("tasks");
      if(newValue === 3) setActiveTab("deals");
    };
  
    if (!activeContact) return null;
  
    const name =
      (activeContact.first_name ? toTitleCase(activeContact.first_name) : "") +
      " " +
      (activeContact.last_name ? toTitleCase(activeContact.last_name) : "");

   const image = activeContact?.image ? `${process.env.REACT_APP_BACKEND_URL}/${activeContact.image}` : null;

   const activeTabValue = () => {
    return tabs.findIndex((tab) => tab === activeTab);
  } 

  const fetchUserContactsActivity = async (contactId) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.getUserContactsActivity}/${contactId}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        const timeZone =
        profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        const filteredData = filteredActivityData(responseData?.data, timeZone);
        setContactsActivity(filteredData);
      } else {
        setContactsActivity([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefreshDetailComp = () => {
    setActiveNote({});
    setActiveTask({});
    setShowForm("");
    fetchUserContactsActivity(activeContact?.id);
  };

  const handleRefreshOnEditContact = async () => {
    const contact = await fetchUserContactsById(activeContact?.id);
    setActiveContact(contact)
  }

  const handleEditContact = () => {
    setShowForm("edit-contact");
    openPanel();
  };

  const handleCreateNote = (event) => {
    event.preventDefault();
    setShowForm("add-note");
    openPanel();
  };

  const handleCreateTask = (event) => {
    event.stopPropagation();
    setShowForm("add-task");
    openPanel();
  };

  const handleEditTask = (task) => {
    setActiveTask(task);
    setShowForm("edit-task");
    openPanel();
  };

  const handleEditNote = (note) => {
    setActiveNote(note);
    setShowForm("edit-note");
    openPanel();
  };

  const handleDeleteTask = async (task) => {
    setActiveTask(task);
    setShowTaskConfirmDelete(true);
    setShowForm("delete-task");
  };

  const handleDeleteNote = async (note) => {
    setActiveNote(note);
    setShowNoteConfirmDelete(true);
    setShowForm("delete-note");
  };

  const handleTaskDeleteConfirmation = async (confirm) => {
    if (confirm && activeTask?.id) {
      let isDeleted = await deleteTask(activeTask.id);
      if (isDeleted) {
        handleRefreshDetailComp();
      }
    }
    setActiveTask({});
    setShowTaskConfirmDelete(false);
  };
  const handleNoteDeleteConfirmation = async (confirm) => {
    if (confirm && activeNote?.id) {
      let isDeleted = await deleteNote(activeNote.id);
      if (isDeleted) {
        handleRefreshDetailComp();
      }
    }
    setActiveNote({});
    setShowNoteConfirmDelete(false);
  };


  useEffect(() => {
    if(activeContact?.id) {
      fetchUserContactsActivity(activeContact.id);
    }
  }, [activeContact?.id]);

  useEffect(() => {
    if(Object.keys(allOptions)?.length === 0) {
      getAllOptions();
    }
  }, []);
    
  
    return (
      <MDBox p={2}>
        {/* Header Section */}
        <MDBox display="flex" alignItems="center">
          <MDBox className="icon-shade" style={{ marginRight: "20px" }} onClick={onClose}>
            <TbArrowBackUp color="#3A93EE" />
          </MDBox>
          <MDBox ml={1} display="flex" alignItems="center">
            <MDBox mr={1}>
              <Avatar name={name} src={image} round={true} size="45" />
            </MDBox>
            {activeContact.full_name ? (
              <Tooltip title={activeContact?.full_name} placement="bottom">
                <MDTypography variant="h5" fontWeight="medium">
                  {toTitleCase(activeContact.full_name)}
                </MDTypography>
              </Tooltip>
            ) : (
              <MDTypography variant="h5" fontWeight="medium">
                {toTitleCase(activeContact.full_name)}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
  
        {/* Tabs Section */}
        <MDBox>
          <MDBox display="flex" alignItems="end" justifyContent="space-between">
            <MDBox sx={{ width: "300px", display: "flex" }}>
              <AppBar
                position="static"
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  "& .MuiTabs-root": { backgroundColor: "transparent" },
                }}
              >
                <Tabs
                  orientation="horizontal"
                  value={activeTabValue()}
                  onChange={handleSetTabValue}
                  sx={{
                    "& .MuiTab-root": { textAlign: "center" },
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <Tab key={tab} label={toTitleCase(tab)} sx={{ textAlign: "center", backgroundColor: "transparent" }} />
                  ))}
                </Tabs>
              </AppBar>
            </MDBox>
  
            {/* Action Buttons */}
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              {[
                { icon: <FiEdit />, label: "Note", func: handleCreateNote },
                { icon: <MdOutlineTaskAlt />, label: "Task", func: handleCreateTask },
                { icon: <MdModeEditOutline />, label: "Edit", func: handleEditContact  },
                { icon: <MdDelete />, label: "Delete", color: "error", func: deleteContactById },
              ].map(({ icon, label, color, func }, index) => (
                <MDBox
                  key={index}
                  ml={1}
                  my={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  onClick={label == "Delete" ? () => func(activeContact?.id) : func}
                >
                  <MDBox className="icon-shade" color={color}>
                    {icon}
                  </MDBox>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {label}
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
  
          <MDBox sx={{ border: "1px solid #cdcdcd", mt: -0.5, ml: 0.5 }} />
            <Grid container mt={0} spacing={2}>
          {activeTab == "overview" && (
            <>
            <Grid item xs={12} md={12}>
              <ContactInfo activeContact={activeContact} defaultFields={defaultFields} customFields={customFields} />
              </Grid>
            <Grid item xs={12} md={12}>
              <NotesInfo activeContactId={Number(activeContact?.id)} />
              </Grid>
            <Grid item xs={12} md={12}>
            <Activity
                  heading={"Recent history"}
                  // type={"History"}
                  allOptions={allOptions}
                  getDealActivity={fetchUserContactsActivity}
                  activityLogs={contactsActivity}
                  handleEditTask={handleEditTask}
                  deleteTask={handleDeleteTask}
                  openPanel={openPanel}
                  setShowForm={setShowForm}
                  deleteNote={handleDeleteNote}
                  handleEditNote={handleEditNote}
                  filteredText={filteredText}
                  setFilteredText={setFilteredText}
                  setActiveTab={setActiveTab}
                  showActivityForComp="contacts"
                />
              </Grid>
            </>
          )}

          {activeTab == "tasks" && 
            <Grid item xs={12} md={12}>
          <TasksInfo activeContactId={Number(activeContact?.id)} />
          </Grid>
          }
          {activeTab == "deals" && 
            <Grid item xs={12} md={12}>
          <DealsInfo activeContactId={Number(activeContact?.id)} />
          </Grid>
          }
          {activeTab == "history" && 
            <Grid item xs={12} md={12}>
            <Activity
                  type={"History"}
                  allOptions={allOptions}
                  getDealActivity={fetchUserContactsActivity}
                  activityLogs={contactsActivity}
                  handleEditTask={handleEditTask}
                  deleteTask={handleDeleteTask}
                  openPanel={openPanel}
                  setShowForm={setShowForm}
                  deleteNote={handleDeleteNote}
                  handleEditNote={handleEditNote}
                  filteredText={filteredText}
                  setFilteredText={setFilteredText}
                  setActiveTab={setActiveTab}
                  showActivityForComp="contacts"
                />
          </Grid>
          }
          </Grid>
        </MDBox>

        {showForm === "add-task" && (
        <MDSidePanel
          header="Add Task"
          onClose={() => {
            setShowForm("");
          }}
        >
          <CreateTask
            onClose={() => {
              setShowForm("");
            }}
            handleRefresh={handleRefreshDetailComp}
            taskDetail={null} 
            showFormInCard={false} 
            dealContact={[activeContact?.id]}
          />
        </MDSidePanel>
      )}
      {showForm === "edit-task" && (
        <MDSidePanel header="Edit Task" onClose={() => setShowForm("")}>
          <CreateTask
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={() => {
              handleRefreshDetailComp();
            }}
            taskDetail={activeTask}
            showFormInCard={false}
            // dealIds={activeTask?.deal_ids}
            type="edit from deal details"
          />
        </MDSidePanel>
      )}

      {showForm === "add-note" && (
        <MDSidePanel header="Create Note" onClose={() => setShowForm("")}>
          <NoteForm
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={handleRefreshDetailComp}
            noteDetail={{contacts: [{id: activeContact?.id}]} } 
            showFormInCard={false}
            showNumberofRows={5}
          />
        </MDSidePanel>
      )}
      {showForm === "edit-note" && (
        <MDSidePanel header="Update Note" onClose={() => setShowForm("")}>
          <NoteForm
            onClose={() => {
              closePanel();
              setShowForm("");
            }}
            handleRefresh={handleRefreshDetailComp}
            noteDetail={activeNote}
            showFormInCard={false}
            showNumberofRows={5}
          />
        </MDSidePanel>
      )}

      {showForm === "delete-task" && (
        <ConfirmDelete
          title="Delete Task?"
          message="Are you sure you want to delete this task?"
          confirm={handleTaskDeleteConfirmation}
          showDialog={showTaskConfirmDelete}
        />
      )}
      {showForm === "delete-note" && (
        <ConfirmDelete
          title="Delete Note?"
          message="Are you sure you want to delete this note?"
          confirm={handleNoteDeleteConfirmation}
          showDialog={showNoteConfirmDelete}
        />
      )}
      {showForm === "edit-contact" && (
        <MDSidePanel header="Edit Contact" onClose={() => setShowForm("")}>
        <ContactForm 
          userDetail={activeContact}
          onClose={()=>{
            closePanel();
            setShowForm("");
          }}
          isEditing={true}
          fields={fields}
          customFields={customFields}
          defaultFields={defaultFields}
          showFormInCard={false}
          handleRefresh={handleRefreshOnEditContact}
        />
        </MDSidePanel>
      )}
      </MDBox>
    );
  };
  
  export default ContactDetails;

