import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import React, { useEffect, useState, useContext } from "react";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import ConfirmDelete from "components/ConfirmDelete";
import GlobalStateContext from "context/GlobalStateContext";
import Link from '@mui/material/Link';
import list from "assets/theme/components/list";


function ListField({
  listId,
  listInfo,
  handleDeleteCard,
  handleRefresh,
  closeNew,
  newPreloaded,
  index,
  setNewPreloaded,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = listId == null ? useState(true) : useState(false);
  const [editedName, setEditedName] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {contactFilteringParams, setContactFilteringParams} = useContext(GlobalStateContext);

  const navigateTo = useNavigate();

  const handleSave = async (event) => {
    event.preventDefault();

    if (editedName) {
      if(editedName.trim() === "") {
        toast.error("Name field is required");
        return;
      } else if(editedName.length > 25) {
        toast.error("Name field should be less than 25 characters");
        return;
      } 
    }


    try {
      if (listId == null) {
        const response = await fetch(`${API_ENDPOINTS?.lists}`, {
          method: "POST",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            name: editedName,
          }),
        });

        const responseData = await response.json();
        if (response.ok) {
          handleDeleteCard();
          toast.success(responseData?.message || "List Created Successfully");
          handleRefresh();
        } else {
          throw new Error(responseData?.message || "Some error occurred while creating list");
        }
      } else {
        const response = await fetch(`${API_ENDPOINTS?.lists}/${listId}`, {
          method: "PUT",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            name: editedName,
          }),
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(responseData?.message || "Field Updated Successfully");
        } else {
          throw new Error(responseData?.message || "Some error occurred while updating field");
        }

        setIsEditing(false);
        handleRefresh();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async () => {
    if (listId == null) {
      setNewPreloaded(false);
      return;
    }

    if (listId) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.lists}/${listId}`, {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          handleRefresh();
          toast.success("List Deleted Successfully");
        } else {
          throw new Error(responseData?.message || "Some error occurred while deleting list");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const moveToUserContacts = (event, listName) => {
    event.preventDefault();
    setContactFilteringParams([listName]);
    navigateTo("/pages/crm/contact-info");
  }

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      await handleDelete();
    }
    setShowConfirmDelete(false);
  };

  const deleteFieldById = async () => {
    setShowConfirmDelete(true);
  };

  useEffect(() => {
    if (listInfo && Object.keys(listInfo).length != 0) {
      setEditedName(listInfo.name);
    }
  }, [listInfo]);

  return (
    <>
      <MDBox component="form" onSubmit={handleSave}>
        <MDBox
          component="li"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgColor={darkMode ? "transparent" : "grey-100"}
          borderRadius="lg"
          p={1}
          sx={index % 2 === 0 && { backgroundColor: "#f0f0f0" }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              {isEditing ? (
                <TextField
                  id="standard-basic"
                  variant="standard"
                  placeholder="Label"
                  label="Name"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  required
                  sx={{display:"inline-block", width:"34%"}}
                />
              ) : (
                <MDTypography variant="button" sx={{ fontWeight: "400", cursor: "pointer"}} width="42%" className="link-field">
                  <Link
                    component="button"
                    variant="body2"
                    underline="always"
                    sx={{ fontWeight: "400"}}
                    onClick={(e)=> moveToUserContacts(e, editedName)}
                    >
                    {editedName}
                  </Link>
                </MDTypography>
              )}

              <MDTypography variant="button" sx={{ fontWeight: "400" }} width={isEditing ? "34%" : "40%"}>
                  { listId != null  ? listInfo.created_at : ''}
                </MDTypography>

              <MDBox
                display="flex"
                alignItems="center"
                mt={{ xs: 2, sm: 0 }}
                ml={{ xs: -1.5, sm: 0 }}
                width={isEditing ? "33%" : "40%"}
                pl={isEditing ? 0 : 1}
                justifyContent="center"
              >
                <MDBox>
                  <MDButton variant="text" color="error" onClick={deleteFieldById} sx={{minWidth:"0", p:0.5}} disabled={listInfo?.user_id == null && listInfo?.id != null}>
                    <Icon>delete</Icon>&nbsp;
                  </MDButton>
                </MDBox>
                {isEditing ? (
                  <MDButton variant="text" color={darkMode ? "white" : "dark"} type="submit" sx={{minWidth:"0", p:0.5, mt:-0.2}}>
                    <Icon>save</Icon>&nbsp;
                  </MDButton>
                ) : (
                  <MDButton
                    disabled={listInfo?.user_id == null && listInfo?.id != null}
                    variant="text"
                    color={darkMode ? "white" : "dark"}
                    type={"button"}
                    onClick={handleEdit}
                    sx={{minWidth:"0", p:0.5}}
                  >
                    <Icon>edit</Icon>&nbsp;
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <ConfirmDelete
        title="Delete User?"
        message="Are you sure you want to delete this field?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
    </>
  );
}

ListField.propTypes = {
  index: PropTypes.number,
  listInfo: PropTypes.object,
  handleRefresh: PropTypes.func,
  listId: PropTypes.number,
};

export default ListField;
