import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";

const MDDialog = ({
  open,
  onClose,
  title,
  children,
  widthConfig,
}) => {
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: widthConfig,
            height: "auto",
            py: 0,
            my: 0,
          },
        }}
      >
        <MDBox p={2}>
          {/* Dialog Header */}
          <MDBox
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              py: 0,
              my: 0,
            }}
          >
            <MDBox sx={{ display: "flex", alignItems: "center", width: "94%", px: 0.5 }}>
              <MDTypography variant="h5" fontWeight="medium">
                {title}
              </MDTypography>
            </MDBox>
            <MDBox
              mt={0.7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "5%",
              }}
            >
              <CloseIcon sx={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={onClose} />
            </MDBox>
          </MDBox>
          <Divider sx={{ bgcolor: "#344767", mb: 2, mt: 1 }} />

          {/* Dialog Content */}
          <Grid container spacing={2}>
            {children}
          </Grid>
        </MDBox>
      </Dialog>
    </Fragment>
  );
};

MDDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  widthConfig: PropTypes.object.isRequired,
};

export default MDDialog;
