
const form = {
    formId: "deal-form",
    formField: {
        name: {
            name: "name",
            label: "Deal Name",
            type: "text",
            errorMsg: "Deal Name is required.",
            invalidMsg: "Deal Name must be at least 1 character",
            isRequired: true,
        },
        contacts: {
            name: "contacts",
            label: "Associate deal to contacts",
            type: "text",
        },
        pipeline: {
            name: "pipeline",
            label: "Pipeline",
            type: "text",
            isRequired: true,
            errorMsg: "Pipeline is required.",
        },
        stage: {
            name: "stage",
            label: "Deal Stage",
            type: "text",
            errorMsg: "Stage is required.",
            isRequired: true,
            
        },
        amount: {
            name: "amount",
            label: "Amount",
            type: "number",
        },
        closeDate: {
            name: "closeDate",
            label: "Close Date",
            type: "date",
        },
        createTask: {
            name: "createTask",
            label: "Create a Task",
            type: "checkbox",
        },
        type: {
            name: "type",
            label: "Type",
            type: "text",
            errorMsg: "Type is required.",
            isRequired: true,
        },
        taskName: {
            name: "taskName",
            label: "Name",
            type: "text",
            errorMsg: "Task Name is required.",
            isRequired: true,
        },
        dueDate: {
            name: "dueDate",
            label: "Due Date",
            type: "date",
        },
        dueTime: {
            name: "dueTime",
            label: "Due Time",
            type: "time",
        }
    },
};

export default form;
