import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import MDTable from "components/MDTables";
import TableContext from "context/Tablecontext";
import DefaultCell from "components/Cell/DefaultCell";
import { changeDateFormatWithTimeZone, toTitleCase, splitNameByComma } from "lib/helper";
import Icon from "@mui/material/Icon";
import ConfirmDelete from "components/ConfirmDelete";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import MDBadge from "components/MDBadge";

// Icons
import { MdCurrencyRupee } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaRegCircleUser } from "react-icons/fa6";
import { HiOutlineChartSquareBar } from "react-icons/hi";

import MDSidePanel from "components/MDSidePanel";
import SidePanelContext from "context/SidePanelContext";
import CreateDeal from "layouts/pages/crm/deals/components/CreateDeal";

const DealsInfo = ({activeContactId}) => {

    const { profileDetails } = useContext(AuthContext);
    const [activeComponent, setActiveComponent] = useState("list");
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { allOptions, getAllOptions, getAllPipeline, allPipelines } = useContext(CrmContext);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
    const [refresh, setRefresh] = useState(0);
    const [isTableInitialize, setIsTableInitialize] = useState(false);
    const [optionState, setOptionState] = useState({});
    const [activeDeal, setActiveDeal] = useState(null);
  
    const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);
    const {
      pageSize,
      search,
      orderBy,
      order,
      currentPage,
      enteries,
      setEnteries,
      isSearching,
      setIsSearching,
      initializeTable,
      setPageSize,
      setEntriesPerPage,
    } = useContext(TableContext);
  
    const [filteringParams, setFilteringParams] = useState([]);
  
    const onClose = () => {
        closePanel();
      };

      const handleDeleteConfirmation = async (confirm) => {
        if (confirm && activeDeal?.id) {
          try {
            const response = await fetch(`${API_ENDPOINTS?.deleteDeal}/${activeDeal?.id}`, {
              method: "DELETE",
              headers: {
                authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
                "Content-type": "application/json; charset=UTF-8",
              },
            });
    
            const responseData = await response.json();
            if (response.ok) {
              handleRefresh();
              toast.success(responseData?.message || "Deal Deleted Successfully");
            } else {
              throw new Error(responseData?.error || "Some error occurred while deleting deal");
            }
          } catch (error) {
            toast.error(error.message);
          }
        }
        setActiveDeal({});
        setShowConfirmDelete(false);
      };


    const ButtonCell = ({ deal }) => {
        return (
          <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <MDBox mr={2}>
              <MDBox
                variant=""
                sx={{ display: "flex", alignItems: "center" }}
                color="error"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveDeal(deal);
                  setShowConfirmDelete(true);
                }}
              >
                <Icon>delete</Icon>&nbsp;
              </MDBox>
            </MDBox>
          </MDBox>
        );
      };

    const loadData = (dealData) => {
        if (dealData && dealData.length > 0) {
          let columns = [
            {
              Header: "id",
              accessor: "id",
              align: "left",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "name",
              accessor: "name",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "stage",
              accessor: "stage",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "close date",
              accessor: "close_date",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "created at",
              accessor: "created_at",
              align: "left",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "action",
              accessor: "action",
              isSorted: false,
              Cell: ({ value }) => <ButtonCell deal={value} />,
            },
          ];
    
          let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
          let rows = dealData.map((deal) => {
            const created_at = deal?.created_at
              ? changeDateFormatWithTimeZone(deal?.created_at, timeZone)
              : "-";
            const closeDate = deal?.close_date
              ? changeDateFormatWithTimeZone(deal?.close_date, timeZone).split(" ")[0]
              : "-";
    
            return {
              id: deal.id.toString(),
              name: deal.name,
              stage: deal.stage ? toTitleCase(deal.stage) : "-",
              close_date: closeDate,
              created_at: created_at,
              action: deal,
            };
          });
    
          setDataTable({ columns: columns, rows: rows });
        } else {
          setDataTable({ columns: [], rows: [] });
        }
      };

    const getAllDeals = async () => {
        let query = "";

      query += `page=${currentPage}&limit=${pageSize}`;

      if (search) {
        query += `&search=${search}`;
      }

      if (orderBy && order) {
        let sortOrder = order === "asc" ? "ASC" : "DESC";
        query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
      }

        try {
          const response = await fetch(`${API_ENDPOINTS?.getAllDeals}?includeRelatedData=${false}&filterField=deal_contacts.contact_id&filterFieldValue=${activeContactId}&${query}`, {
            method: "GET",
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
          const responseData = await response.json();
          if (response.ok) {
            loadData(responseData?.data.items);
    
            const { data } = resjson;
            const totalPage = Math.ceil(data.totalItems / pageSize);
            const startIndex = (currentPage - 1) * pageSize + 1;
            const endIndex = Math.min(currentPage * pageSize, data.totalItems);
            setEnteries((prev)=> ({...prev,
                totalItems: data.totalItems,
                totalPages: data.totalPages,
                totalMediaCount: totalPage,
                enteriesStart: startIndex,
                enteriesEnd: endIndex
            }));
          } else {
            loadData([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      useEffect(() => {
  
        initializeTable();
        setPageSize("100");
        setEntriesPerPage({ entries: ["25", "50", "100", "150", "200"] });
        setIsTableInitialize(true);
        getAllPipeline();
      }, []);
  
      useEffect(() => {
  
          if(activeContactId) {
            getAllDeals();
          }
    
      }, [refresh, filteringParams, pageSize, currentPage, orderBy, order, activeContactId]);
    
      useEffect(() => {
        if (isSearching) {
          clearTimeout(isSearching);
        }
    
        const timeoutId = setTimeout(() => {
          if(isTableInitialize && activeContactId) {
            getAllDeals();
          }
        }, 500);
        setIsSearching(timeoutId);
    
        return () => clearTimeout(timeoutId);
      }, [search, activeContactId]);
  
        const handleRefresh = () => {
          setRefresh(!refresh);
        };

    return  (
        <>
        <Card id="deals-info">
          <>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">
                  Deals
                </MDTypography>
              </MDBox>
              <MDBox
                pt={3}
                px={2}
                display="flex"
                alignItems="center"
                sx={{ gap: "7px", padding: "0" }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                  onClick={() => {
                    setActiveComponent("create");
                    openPanel();
                  }}
                >
                  Create Deals
                </MDButton>
              </MDBox>
            </MDBox>

            <MDBox my={2}>
              <MDTable
                table={dataTable}
                canSearch={true}
                canFilter={true}
                licenceDetails={[]}
              ></MDTable>
            </MDBox>
          </>
        {activeComponent === "create" && (
          <MDSidePanel header="Create Deal" onClose={() => onClose()}>
        <CreateDeal
          onClose={onClose}
          handleRefresh={handleRefresh}
          activePipeline={null}
          activeStage={null}
          showFormInCard={false} 
          contacts={[activeContactId]}   
        />
          </MDSidePanel>
        )}
      </Card>
      <ConfirmDelete
        title="Delete Deal?"
        message="Are you sure you want to delete this deal?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
        </>
    )  
}

export default DealsInfo

