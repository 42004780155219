import React, { useState } from "react";
import MDBox from "components/MDBox";
import Avatar from "@mui/material/Avatar";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import ShowImageModal from "./ShowImageModal";

const RightMessage = ({ message, avatar, name, time, image }) => {
    const [open, setOpen] = useState(false);
  return (
    <>
    <MDBox
      className="right-message"
      my={3}
      width="100%"
      sx={{ display: "flex", justifyContent: "flex-end" }}
    >
      <MDBox sx={{ display: "flex", justifyContent: "flex-end", width: "auto", maxWidth: "70%" }}>
        <MDBox width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end">
          {message && message?.length > 0 && (<MDBox width="100%" display="flex" alignItems="center" justifyContent="flex-end">
          <Card className="message-content" sx={{display: "flex", justifyContent: "flex-end"}}>
            <MDBox p={2}>
              <MDTypography variant="body2" fontWeight="regular" color="white" sx={{ whiteSpace: "pre-line" }}>
                {message}
              </MDTypography>
            </MDBox>
          </Card>
          </MDBox>)}
          {image &&(<MDBox mt={message && message?.length > 0 ? 2 : 0} display="flex" alignItems="center" justifyContent="flex-end" width="100%" align="right">
          <Card className="message-image-card cursor-pointer" onClick={() => setOpen(true)}>
              <img src={image} />
          </Card>
          </MDBox>)}
          <MDBox
            mt={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            className="message-text-light"
          >
            <MDTypography variant="body2" fontWeight="regular" className="message-text-light">
              {time}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox ml={2}>
          {avatar ? (
            <Avatar alt={name} src={avatar} sx={{ width: 56, height: 56 }} />
          ) : (
            <Avatar sx={{ width: 56, height: 56 }} className="avatar-text-color">{name}</Avatar>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
    <ShowImageModal open={open} setOpen={setOpen} image={image} />
    </>
  );
};

export default RightMessage;
