import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import { changeDateFormatWithTimeZone } from "lib/helper";
import moment from "moment";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import {convertSelectedDateToDayjs, formateDateToYYYYMMDD} from "lib/helper";
 
dayjs.extend(utc);

const currentDate = dayjs().startOf('day');

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const DealInfoCard = ({ activeDeal, handleRefreshDetailComp }) => {
  const [firstInfoCardFields, setFirstInfoCardFields] = useState({
    amount: "",
    close_date: null,
    created_at: null,
    updated_at: null,
    name: "",
  });
  const [expanded, setExpanded] = useState(true);
  const [moreInfoExpanded, setMoreInfoExpanded] = useState(false);
  const [showFirstCardButton, setShowFirstCardButton] = useState(false);
  const { profileDetails } = useContext(AuthContext);
  const { updateDeal } = useContext(CrmContext);
  const now = dayjs();
  const currentDate = dayjs().startOf('day');
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDateSelection = (value) => {
    let formattedDate = convertSelectedDateToDayjs(value);
    setFirstInfoCardFields((prev) => {
      return {
        ...prev,
        close_date: formattedDate,
      };
    })
    setShowFirstCardButton(true);
  };

  const handleMoreInfoExpandClick = () => {
    setMoreInfoExpanded(!moreInfoExpanded);
  };

  const handleChange = (event) => {
    setFirstInfoCardFields({
      ...firstInfoCardFields,
      [event.target.id]: event.target.value,
    });

    setShowFirstCardButton(true);
  };

  const handleSave = async () => {
    const isUpdated = await updateDeal(
      { amount: firstInfoCardFields?.amount,
        close_date: firstInfoCardFields?.close_date ? formateDateToYYYYMMDD(firstInfoCardFields?.close_date) : null
       },
      activeDeal?.id
    );
    if (isUpdated) {
      setShowFirstCardButton(false);
      handleRefreshDetailComp();
    }
  };

  const formatDate = (date) => {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
  }

  useEffect(() => {
    let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const closeDate = changeDateFormatWithTimeZone(activeDeal?.close_date, timeZone);
    const createdAt = changeDateFormatWithTimeZone(activeDeal?.created_at, timeZone);
    const updatedAt = changeDateFormatWithTimeZone(activeDeal?.updated_at, timeZone);

    setFirstInfoCardFields((prev) => {
      return {
        ...prev,
        amount: activeDeal?.amount,
        close_date: closeDate ? closeDate : null,
        created_at: createdAt ? createdAt : null,
        updated_at: updatedAt ? updatedAt : null,
        name: activeDeal?.name,
      };
    });
  }, [activeDeal, profileDetails]);
  return (
    <MDBox p={1}>
      <CardActions disableSpacing>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Information
          </MDTypography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </MDBox>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MDBox p={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox sx={{ width: "100%" }}>
                <MDTypography variant="subtitle2" fontWeight="normal" color="text">
                  Name
                </MDTypography>
                <TextField
                  disabled
                  inputProps={{
                    readOnly: true,
                    style: { cursor: "not-allowed", color: "red" },
                  }}
                  sx={{ width: "100%" }}
                  id="name"
                  value={
                    activeDeal?.contacts?.length > 1
                      ? activeDeal?.contacts[0]?.name
                      : activeDeal?.name
                  }
                  variant="standard"
                  placeholder="Name"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDBox sx={{ width: "100%" }}>
                <MDTypography variant="subtitle2" fontWeight="normal" color="text">
                  Amount
                </MDTypography>
                <TextField
                  sx={{ width: "100%" }}
                  id="amount"
                  value={firstInfoCardFields.amount}
                  variant="standard"
                  placeholder="Amount"
                  onChange={handleChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDBox sx={{ width: "100%" }}>
                <MDTypography variant="subtitle2" fontWeight="normal" color="text">
                  Close date
                </MDTypography>
              </MDBox>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={12}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  value={firstInfoCardFields.close_date ? dayjs(firstInfoCardFields.close_date).utc() : null}
                  onChange={(value) => handleDateSelection(value)}
                  minDate={currentDate}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputLabelProps: {
                        shrink: true,
                      },
                      sx: { width: "99%" },
                    },
                  }}
                />
              </DemoContainer>
            </Grid>
          </LocalizationProvider>

            </Grid>
            <Grid item xs={12} sm={12}>
              <MDBox sx={{ width: "100%" }}>
                <MDTypography variant="subtitle2" fontWeight="normal" color="text">
                  Last updated date
                </MDTypography>
              </MDBox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={12}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  value={firstInfoCardFields.updated_at ? dayjs(firstInfoCardFields.updated_at).utc() : null}
                  onChange={(value) => handleDateSelection(value)}
                  disabled={true}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputLabelProps: {
                        shrink: true,
                      },
                      sx: { width: "99%" },
                    },
                  }}
                />
              </DemoContainer>
            </Grid>
          </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDBox sx={{ width: "100%" }}>
                <MDTypography variant="subtitle2" fontWeight="normal" color="text">
                  Created at
                </MDTypography>
              </MDBox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={12}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ overflow: "hidden", width: "100%",  mt: "-7px" }}
              >
                <DatePicker
                  views={["year", "month", "day"]}
                  value={firstInfoCardFields.created_at ? dayjs(firstInfoCardFields.created_at).utc() : null}
                  onChange={(value) => handleDateSelection(value)}
                  disabled={true}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputLabelProps: {
                        shrink: true,
                      },
                      sx: { width: "99%" },
                    },
                  }}
                />
              </DemoContainer>
            </Grid>
          </LocalizationProvider>
            </Grid>
          </Grid>
        </MDBox>
      </Collapse>
      <CardActions disableSpacing>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            More information
          </MDTypography>
          <ExpandMore
            expand={moreInfoExpanded}
            onClick={handleMoreInfoExpandClick}
            aria-expanded={moreInfoExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </MDBox>
      </CardActions>
      <Collapse in={moreInfoExpanded} timeout="auto" unmountOnExit>
        <MDBox p={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox sx={{ width: "100%" }}>
                <MDTypography variant="subtitle2" fontWeight="normal" color="text">
                  Deal owner
                </MDTypography>
                <TextField
                  disabled
                  sx={{ width: "100%" }}
                  inputProps={{
                    readOnly: true,
                    style: { cursor: "not-allowed", color: "red" },
                  }}
                  id="full_name"
                  value={profileDetails?.full_name}
                  variant="standard"
                  placeholder="Deal Owner"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Collapse>
      {showFirstCardButton && (
        <MDBox mt={1} p={1} display="flex" justifyContent="flex-start" alignItems="center">
          <>
            <MDButton
              color="dark"
              variant="outlined"
              size="small"
              onClick={() => setShowFirstCardButton(false)}
            >
              Cancel
            </MDButton>
            <MDButton
              sx={{ ml: 1 }}
              color="dark"
              variant="gradient"
              size="small"
              onClick={handleSave}
            >
              Save
            </MDButton>
          </>
        </MDBox>
      )}
    </MDBox>
  );
};

export default DealInfoCard;
