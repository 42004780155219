/* eslint-disable */
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import React, { useEffect, useState, useContext } from "react";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import ContactForm from "../ContactForm";
import API_ENDPOINTS from "apiConfig";
import Avatar from "react-avatar";
import ConfirmDelete from "components/ConfirmDelete";
import FilterMenu from "../FilterMenu";
import Tooltip from "@mui/material/Tooltip";
import { ImCross } from "react-icons/im";
import GlobalStateContext from "context/GlobalStateContext";
import ImportContacts from "../ImportContacts";
import DefaultCell from "components/Cell/DefaultCell";
import { changeDateFormatWithTimeZone, splitNameByComma } from "lib/helper";
import AuthContext from "context/Authcontext";
import MDTable from "components/MDTables";
import TableContext from "context/Tablecontext";
import CrmContext from "context/CrmContext";
import Chip from "@mui/material/Chip";
import { IoListSharp } from "react-icons/io5";
import MDMenu from "components/MDMenu";
import ContactDetails from "../ContactDetails"
import { IoMdEye } from "react-icons/io";
import Link from '@mui/material/Link';
import { useSearchParams } from "react-router-dom";

function ContactsView() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [bills, setBills] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [editing, setEditing] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isFieldView, setIsFieldView] = useState(false);
  const [showImportComp, setShowImportComp] = useState(false);
  const [fields, setFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [defaultFields, setDefaultFields] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [filteringParams, setFilteringParams] = useState([]);
  const { contactFilteringParams, setContactFilteringParams } = useContext(GlobalStateContext);
  const { profileDetails } = useContext(AuthContext);
  const { listsOption, setListsOption, fetchAllLists } = useContext(CrmContext);
  const [startSearching, setStartSearching] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isTableInitialize, setIsTableInitialize] = useState(false);
  const [activeContact, setActiveContact] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [activeComponent, setActiveComponent] = useState("list");
  const [searchParams, setSearchParams] = useSearchParams();
  let userFromParams = searchParams.get('user');
  const [userId, setUserId] = useState(userFromParams);
  const [users, setUsers] = useState([]);

  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
    setPageSize,
    setEntriesPerPage,
  } = useContext(TableContext);

  const handleEdit = (data) => {
    setEditing(data);
    setIsEditing(true);
  };

  const removeUserParam = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('user'); // Remove 'user' parameter
    window.history.pushState({}, '', url); // Update URL without reloading
    setUserId('');
  };

  const handleView = (user) => {
    setActiveContact(user);
    setShowDetails(true);
    removeUserParam();
  }

  const handleClose = () => {
    setEditing({});
    setIsEditing(false);
    setIsCreating(false);
    setIsFieldView(false);
    setShowDetails(false);
  };

  const handleCreate = () => {
    setIsCreating(true);
  };
  const toTitleCase = (label) => {
    return label
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleRemoveFilter = (listName) => {
    let index = filteringParams?.findIndex((params) => params == listName);
    if (index !== -1) {
      setFilteringParams((filteringParams) => [
        ...filteringParams.slice(0, index),
        ...filteringParams.slice(index + 1),
      ]);
    }
  };

  const initializeFilteringParams = () => {
    if(contactFilteringParams.length > 0) {
      setContactFilteringParams([]);
    }
  };

  useEffect(() => {
    if (contactFilteringParams.length > 0) {
      setFilteringParams(contactFilteringParams);
    }
    initializeFilteringParams();
  }, [contactFilteringParams]);

  const ChipCell = ({ value, type }) => {
    return value !== "-" ? (
      <MDBox mt={0.25} mb={0.65} display="flex" alignItems="center">
        <Chip
          icon={<IoListSharp />}
          label={value[0]}
          size="medium"
          style={{ backgroundColor: "#b8cfeb" }}
        />
        {value.length > 1 && (
          <MDBox ml={1}>
            <MDMenu
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              menuWidth={230}
              triggerElement={
                <Chip
                  label={`+ ${value.length - 1}`}
                  size="small"
                  className="table-chip-bg-color cursor-pointer"
                />
              }
            >
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">Additional lists</MDTypography>
              </MDBox>
              {value?.map((name, index) => (
                index !== 0 && <MDBox mt={1}>{name}</MDBox>
              ))}
            </MDMenu>
          </MDBox>
        )}
      </MDBox>
    ) : (
      <MDBox>-</MDBox>
    );
  };

  const TagsCell = ({ value }) => {
    if (!value || value === "-") {
      return <MDBox>-</MDBox>;
    }
  
    let status = [];
    try {
      status = JSON.parse(value);
      if (!Array.isArray(status)) {
        status = [];
      }
    } catch (error) {
      console.error("Invalid JSON format in TagsCell:", error);
      status = [];
    }
  
    if (status.length === 0) {
      return <MDBox>-</MDBox>;
    }
  
    const tagClassName = status[0]?.split(" ").join("-").toLowerCase();
  
    return (
      <MDBox mt={0.25} mb={0.65} display="flex" alignItems="center">
        <Chip label={status[0]} size="medium" className={`tag-cell ${tagClassName}`} />
        {status.length > 1 && (
          <MDBox ml={1}>
            <MDMenu
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              menuWidth={230}
              triggerElement={
                <Chip
                  label={`+ ${status.length - 1}`}
                  size="small"
                  className={`tag-cell table-chip-bg-color cursor-pointer`}
                />
              }
            >
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">
                  Additional status
                </MDTypography>
              </MDBox>
              <MDBox display="flex" flexWrap="wrap" alignItems="center" mt={1} gap={1}>
                {status.map((name, index) => {
                  if (index === 0) return null;
                  const tagClass = name?.split(" ").join("-").toLowerCase();
                  return <Chip key={index} label={name} size="medium" className={`tag-cell ${tagClass}`} />;
                })}
              </MDBox>
            </MDMenu>
          </MDBox>
        )}
      </MDBox>
    );
  };
  

  function AvatarCell({ image, first_name, last_name, user }) {
    const name =
      first_name && last_name ? toTitleCase(first_name) + " " + toTitleCase(last_name) : "";
    return (
      <MDBox display="flex" alignItems="center">
        <MDBox mr={1}>
        <Link
            component="button"
            variant="body2"
            sx={{ fontWeight: "400"}}
            onClick={(e)=> {
              e.preventDefault();
              handleView(user);
            }}>
          <Avatar name={name} src={image} round={true} size="28" />
          </Link>
        </MDBox>
        
       
          <Link
            component="button"
            variant="body2"
            sx={{ fontWeight: "400"}}
            onClick={(e)=> {
              e.preventDefault();
              handleView(user);
            }}
            >
             <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }} className="cursor-pointer">
            {name}
            </MDTypography>
          </Link>

      </MDBox>
    );
  }

  const ButtonCell = ({ user }) => {
    return (
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MDBox mr={2}>
          <MDBox
            variant=""
            sx={{ display: "flex", alignItems: "center" }}
            color="error"
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              deleteContactById(user.id);
            }}
          >
            <Icon>delete</Icon>&nbsp;
          </MDBox>
        </MDBox>
        <MDBox
          mr={2}
          variant="caption"
          sx={{ display: "flex", alignItems: "center" }}
          color="info"
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            handleEdit(user);
          }}
        >
          <Icon>edit</Icon>&nbsp;
        </MDBox>
        <MDBox
          mr={2}
          variant="caption"
          sx={{ display: "flex", alignItems: "center" }}
          color="info"
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            handleView(user);
          }}
        >
          <IoMdEye />&nbsp;
        </MDBox>
      </MDBox>
    );
  };

  const fetchFields = async () => {
    try {
      const userFieldsResponse = await fetch(`${API_ENDPOINTS?.getAllFieldsByUserId}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const userFieldsData = await userFieldsResponse.json();
      const allFieldsResponse = await fetch(`${API_ENDPOINTS?.getAllFields}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      let allFieldsData = await allFieldsResponse.json();

      const allLists = await fetchAllLists();

      if (allFieldsData && Array.isArray(allFieldsData.data)) {
        allFieldsData.data = allFieldsData?.data?.filter((item) => item.name !== "image")?.map((item) => {
          if (item.name === "lists") {
            return {
              ...item,
              options: JSON.stringify(allLists),
            };
          }
          return item;
        });
      }

      // Move interaction field to the bottom
      const intractionFieldIndex = allFieldsData?.data?.findIndex((item) => item.name === "interaction");
      const intractionField = allFieldsData?.data[intractionFieldIndex];
      allFieldsData?.data?.splice(intractionFieldIndex, 1);
      allFieldsData?.data?.push(intractionField);

      // Merge fields from both requests
      const combinedFields = [...(allFieldsData?.data || []), ...(userFieldsData?.data || [])];
      setFields(combinedFields);
      setDefaultFields(allFieldsData?.data || []);
      setCustomFields(userFieldsData?.data || []);
    } catch (error) {
      console.error("Error fetching fields:", error);
    }
  };

  const loadData = (data) => {
    const fieldsNotToDisplay = [
      "id",
      "image",
      "first_name",
      "last_name",
      "full_name",
      "headline",
      "title",
      "location",
      "interaction",
      "home_email",
      "enriched_email",
      "business_email",
      "linkedin_id",
      "username",
      "linkedIn_url",
      "website_url",
      "user_id",
      "custom_data",
      "linkedin_url",
      "updated_at",
      "deleted_at",
      "company",
      "primary_phone_number"
    ];

    let columns = [];

    Object.keys(data[0] || {}).forEach((key) => {
      if (!fieldsNotToDisplay.includes(key)) {
        columns.push({
          Header: key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          accessor: key,
          align: "left",
          width: key == "work_email" ? "35%" : "55%",
          ...(key === 'lists' 
            ? { Cell: ({ value }) => <ChipCell value={value} /> } 
            : key === 'status' 
            ? { Cell: ({ value }) => <TagsCell value={value} /> } 
            : { Cell: ({ value }) => <DefaultCell value={value} /> }),
        });
      }
    });

    // Add custom columns explicitly
    columns.unshift({
      Header: "User",
      accessor: "full_name",
      width: "45%",
      Cell: ({ value: [image, first_name, last_name, user] }) => (
        <AvatarCell image={image} first_name={first_name} last_name={last_name} user={user} />
      ),
    });
    columns.push({
      Header: "action",
      accessor: "action",
      isSorted: false,
      Cell: ({ value }) => <ButtonCell user={value} />,
    });

    let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    let rows = data.map((user) => {
      const image = user?.image ? `${process.env.REACT_APP_BACKEND_URL}/${user.image}` : null;

      const created_at = user?.created_at
        ? changeDateFormatWithTimeZone(user?.created_at, timeZone)
        : "N/A";
      let listsDetails = user.lists? splitNameByComma(user.lists): "-";
      return {
        full_name: [image, user.first_name, user.last_name, user],
        work_email: user.work_email || "-",
        primary_phone_number: user.primary_phone_number || "-",
        company: user.company || "-",
        created_at: created_at || "-",
        lists: listsDetails,
        action: user,
        status: user.status,
      };
    });

    if (columns.length > 0) {
      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getUserLists = async (filterdata = []) => {
    try {
      setBills([]);
      fetchFields();
      let query = "";
      const filterData = filteringParams ||  filterdata;

      query += `page=${currentPage}&limit=${pageSize}`;
      if (filterData?.length > 0) {
        query = `&lists=${filterData.join(",")}`;
      }

      if (search) {
        query += `&search=${search}`;
      }

      if (orderBy && order) {
        let sortOrder = order === "asc" ? "ASC" : "DESC";
        query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
      }

      const response = await fetch(`${API_ENDPOINTS?.getAllContacts}` + `?${query}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const resjson = await response.json();
      if (response.ok) {
        loadData(resjson?.data.items);
        setUsers(resjson?.data.items);
        const { data } = resjson;
        const totalPage = Math.ceil(data.totalItems / pageSize);
        const startIndex = (currentPage - 1) * pageSize + 1;
        const endIndex = Math.min(currentPage * pageSize, data.totalItems);
        setEnteries({
          ...enteries,
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          totalMediaCount: totalPage,
          enteriesStart: startIndex,
          enteriesEnd: endIndex,
        });
      } else {
        loadData([]);
      }
    } catch (error) {
      console.log("Error fetching user lists:", error);
    }
  };

  useEffect(() => {
    if(!isEditing && !isCreating && !isFieldView && !showImportComp && !showDetails) {
      getUserLists();
    }
  }, [refresh, editing, pageSize, filteringParams, currentPage, orderBy, order]);

  useEffect(() => {
    if (startSearching) {
      if (isSearching) {
        clearTimeout(isSearching);
      }
  
      const timeoutId = setTimeout(() => {
        if(isTableInitialize) {
          if(!isEditing && !isCreating && !isFieldView && !showImportComp && !showDetails) {

            getUserLists();
          }
        }
      }, 500);
      setIsSearching(timeoutId);
  
      return () => clearTimeout(timeoutId);
    }
    setStartSearching(true);
  }, [search]); 

  useEffect(() => {
    initializeTable();
    setPageSize("100");
    setEntriesPerPage({ entries: ["25", "50", "100", "150", "200"] });
    setIsTableInitialize(true);
  }, []);

  useEffect(() => {
    if (userId && users) {
      const userToView = users.find((item) => item?.id === Number(userId));
      if (userToView) {
        handleView(userToView);
      }
    }
  }, [userId, users])

  const handleDeleteCard = async (id) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.deleteContact}/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        toast.success(jsonResponse.message);
        setDataTable({ columns: [], rows: [] });
        setRefresh(true);
        setEditing({});
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      await handleDeleteCard(selectedContactId);
    }
    setShowConfirmDelete(false);
  };

  const deleteContactById = async (id) => {
    setSelectedContactId(id);
    setShowConfirmDelete(true);
  };

  return (
    <>
      {!isEditing && !isCreating && !isFieldView && !showImportComp && !showDetails && (
        <Card id="delete-account">
          <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium">
                Saved Contacts
              </MDTypography>
            </MDBox>
            <MDBox
              pt={3}
              px={2}
              display="flex"
              alignItems="center"
              sx={{ gap: "7px", padding: "0" }}
            >
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                onClick={async () => {
                  handleCreate();
                }}
              >
                Create Contacts
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                onClick={() => setShowImportComp(true)}
              >
                Import Contacts
              </MDButton>
            </MDBox>
          </MDBox>

          {dataTable && dataTable?.rows?.length === 0 && (
            <MDBox pt={1} px={2}>
              <MDTypography variant="caption" fontWeight="medium">
                Your saved profile contact details will appear here when you save a linkedin
                profile.
              </MDTypography>
            </MDBox>
          )}
          <MDBox py={1} mx={3}>
            <MDTable table={dataTable} canSearch={true} canFilter={true} licenceDetails={[]}>
              {/* filters */}
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: { xs: 2.8, sm: 0, md: 0 },
                      width: {
                        xs: "100%",
                        sm: "auto",
                        md: "auto",
                      },
                      justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                    }}
                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                        mr: { xs: 0, sm: 0.2, md: 0 },
                      }}
                    >
                      {filteringParams?.length > 0 && (
                        <MDBox display="flex" alignItems="center">
                          {filteringParams.map((params, index) => {
                            const filteredtitle = "Lists";
                            const filteredValue = params
                              ?.split("_")
                              .map((word) => word[0].toUpperCase() + word.slice(1))
                              .join(" ");
                            return (
                              <>
                                <MDButton
                                  variant="outlined"
                                  color="info"
                                  size="small"
                                  sx={{ ml: index !== 0 ? 1 : 0 }}
                                >
                                  <MDBox
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    mx={0}
                                    px={0}
                                  >
                                    <Tooltip
                                      disableFocusListener
                                      disableTouchListener
                                      title="Clear Filter"
                                      placement="top"
                                    >
                                      <MDBox
                                        sx={{ display: "flex", alignItems: "center", pr: 1.5 }}
                                      >
                                        <ImCross
                                          size={12}
                                          color="info"
                                          className="icon-color cursor-pointer"
                                          onClick={() => handleRemoveFilter(params)}
                                        />
                                      </MDBox>
                                    </Tooltip>
                                    <MDBox
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <MDTypography
                                        variant="button"
                                        className="icon-color"
                                        fontWeight="bold"
                                        noWrap
                                      >
                                        {filteredtitle}: {filteredValue}
                                      </MDTypography>
                                      <MDBox className="line-over-text" />
                                    </MDBox>
                                  </MDBox>
                                </MDButton>
                              </>
                            );
                          })}
                        </MDBox>
                      )}
                    </MDBox>
                    <MDBox
                      sx={{
                        ml: 1.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                      }}
                    >
                      <FilterMenu
                        filteringParams={filteringParams}
                        setFilteringParams={setFilteringParams}
                        listsOption={listsOption}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDTable>
          </MDBox>
        </Card>
      )}
      {isEditing && (
        <ContactForm 
          userDetail={editing}
          onClose={handleClose}
          isEditing={isEditing}
          fields={fields}
          customFields={customFields}
          defaultFields={defaultFields}
          setDefaultFields={setDefaultFields}
        />
      )}
      {isCreating && (
        <ContactForm
          userDetail={null}
          onClose={handleClose}
          isEditing={isEditing}
          fields={fields}
          customFields={customFields}
          defaultFields={defaultFields}
          setDefaultFields={setDefaultFields}
        />
      )}
      {showImportComp && (
        <ImportContacts
          setShowImportComp={setShowImportComp}
          lists={listsOption}
          refresh={refresh}
          setRefresh={setRefresh}
          defaultFields={defaultFields}
          customFields={customFields}
        />
      )}
      {showDetails && (
        <ContactDetails
          activeContact={activeContact}
          onClose={handleClose}
          fields={fields}
          customFields={customFields}
          defaultFields={defaultFields}
          deleteContactById={deleteContactById}
          setActiveContact={setActiveContact}
        />
      )}
      <ConfirmDelete
        title="Delete User?"
        message="Are you sure you want to delete this user?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
    </>
  );
}

export default ContactsView;
