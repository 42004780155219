import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { FiEdit } from "react-icons/fi";
import { MdOutlineTaskAlt } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaRegThumbsDown } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa6";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Avatar from "react-avatar";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { TbArrowBackUp } from "react-icons/tb";

import { Tooltip } from "@material-ui/core";
import { toTitleCase } from "lib/helper";
import Chip from "@mui/material/Chip";

// utililities
import moment from "moment";

// context
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";

import { IoListSharp } from "react-icons/io5";

// page comoponents

const DefaultInfo = ({ activeContact, defaultFields }) => {
  const navigateTo = useNavigate();
  const ChipsForList = ({ lists }) => {
    if (lists?.length > 0) {
      const listNames = lists.split(",");
      if (listNames?.length > 0) {
        return (
          <MDBox display="flex" alignItems="center">
            {listNames.map((listName) => (
              <MDBox key={listName} mr={1} mb={1}>
                <Chip
                  icon={<IoListSharp className="theme-text-color" />}
                  label={listName}
                  size="medium"
                  className="theme-text-color theme-bg-color"
                  onClick={(e) => {
                    navigateTo(`/pages/crm/lists`);
                  }}
                />
              </MDBox>
            ))}
          </MDBox>
        );
      } else {
        return "No Lists";
      }
    }

    return null;
  };

  const ChipForStatus = ({ value }) => {
    if (!value) return "NA"; // Handles undefined or null cases
  
    let status = [];
  
    try {
      status = JSON.parse(value);
      if (!Array.isArray(status)) {
        status = [];
      }
    } catch (error) {
      console.error("Invalid JSON format in TagsCell:", error);
      status = [];
    }
  
    if (status.length === 0) return "NA";
  
    return (
      <MDBox display="flex" flexWrap="wrap">
        {status.map((name) => {
          const tagClass = name?.split(" ").join("-").toLowerCase();
          return (
            <MDBox key={name} mr={1} mb={1}>
              <Chip label={name} size="medium" className={`tag-cell ${tagClass}`} />
            </MDBox>
          );
        })}
      </MDBox>
    );
  };
  

  const filteredFields = defaultFields.filter((field) => field && activeContact[field?.name]);

  // Divide fields into two roughly equal columns
  const midIndex = Math.ceil(filteredFields.length / 2);
  const leftFields = filteredFields.slice(0, midIndex);
  const rightFields = filteredFields.slice(midIndex);

  return (
    <MDBox p={2}>
      <MDTypography variant="h6" fontWeight="medium">
        Contact Info
      </MDTypography>
      <MDBox py={3}>
        {/* <Grid container spacing={3}> */}
        <Grid container spacing={2} sx={{ display: "flex", alignItems: "start" }}>
          {/* Left Column */}
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
            {leftFields.map((field) => {
              if(field?.label && activeContact[field?.name] && activeContact[field?.name] !== "null") {
                return (
                <Grid container spacing={1} key={field?.name} sx={{ marginBottom: 1, flexWrap :"nowrap" }}>
                <Grid item>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    {field?.label}:&nbsp;
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {/* {activeContact[field?.name] || "N/A"} */}
                    {field?.name == "lists" ? (
                      <ChipsForList lists={activeContact[field?.name]} />
                    ) : (
                      <>
                        {field?.name === "status" ? (
                          <ChipForStatus value={activeContact?.[field?.name]} />
                        ) : (
                          activeContact?.[field?.name] || "N/A"
                        )}
                      </>
                    )}
                  </MDTypography>
                </Grid>
              </Grid>
              )
              }

            })}
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
            {rightFields.map((field) => {
              if(field?.label && activeContact[field?.name] && activeContact[field?.name] !== "null") {
                return (
                  <Grid container spacing={1} key={field?.name} sx={{ marginBottom: 1, flexWrap :"nowrap" }}>
                <Grid item>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    {field?.label}:&nbsp;
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {/* {activeContact[field?.name] || "N/A"} */}
                    {field?.name == "lists" ? (
                      <ChipsForList lists={activeContact[field?.name]} />
                    ) : (
                      <>
                        {field?.name === "status" ? (
                          <ChipForStatus value={activeContact?.[field?.name]} />
                        ) : (
                          activeContact?.[field?.name] || "N/A"
                        )}
                      </>
                    )}
                  </MDTypography>
                </Grid>
              </Grid>
                )
              }
            })}
          </Grid>
        </Grid>
        {/* </Grid> */}
      </MDBox>
    </MDBox>
  );
};

const CustomInfo = ({ activeContact, customFields }) => {
  const userCustomData = JSON.parse(activeContact.custom_data || "[]");

  // Step 1: Create an object from customFields and custom_data values
  const customDataObject = customFields.reduce((acc, field) => {
    const fieldValue = userCustomData.find((item) => field.name in item)?.[field.name] || "N/A";
    if (fieldValue !== "N/A") {
      acc[field.name] = { label: field.label, value: fieldValue };
    }
    return acc;
  }, {});

  // Step 2: Divide the object into two halves
  const entries = Object.entries(customDataObject);
  const midIndex = Math.ceil(entries.length / 2);
  const leftEntries = entries.slice(0, midIndex);
  const rightEntries = entries.slice(midIndex);

  return (
    <>
      {Object.keys(entries)?.length > 0 && (
        <MDBox p={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Additional Info
          </MDTypography>
          <MDBox py={3}>
            <Grid container spacing={2} sx={{ display: "flex", alignItems: "start" }}>
              {/* Left Column */}
              <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                {leftEntries.map(([key, field]) => (
                  <Grid container spacing={1} key={key} sx={{ marginBottom: 1, flexWrap :"nowrap" }}>
                    <Grid item>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        {field.label}:&nbsp;
                      </MDTypography>
                    </Grid>
                    <Grid item>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {field.value}
                      </MDTypography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                {rightEntries.map(([key, field]) => (
                  <Grid container spacing={1} key={key} sx={{ marginBottom: 1, flexWrap :"nowrap" }}>
                    <Grid item>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        {field.label}:&nbsp;
                      </MDTypography>
                    </Grid>
                    <Grid item>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {field.value}
                      </MDTypography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

const ContactInfo = ({ activeContact, defaultFields, customFields }) => {
  return (
    <Card>
      <DefaultInfo activeContact={activeContact} defaultFields={defaultFields} />
      <CustomInfo activeContact={activeContact} customFields={customFields} />
    </Card>
  );
};

export default ContactInfo;
